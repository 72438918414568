@import "../../../common/styles/fonts";

.card-course-viewer {
  position: relative;
  max-width: 70ch;
  margin: auto;
  padding: 1.5rem 1.5rem 85px;
  @include modern-zoltrain-font;
}

