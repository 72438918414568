.user-form-wrapper{}

.user-form-wrapper .user-profile-image{
    width: 128px;
    height: 128px;
    margin: 0 auto;
    margin-bottom: 20px;
    border-radius: 50%;
}

.user-form-wrapper .user-last-first-name {
    font-family: 'Brandon-med', sans-serif;
    text-transform: uppercase;
    margin-bottom: 45px;
    color:#000;
}