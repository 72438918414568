.answered-survey-card-wrapper {
  padding-left: 74px;
  padding-right: 74px;
  .card-course-card {
    box-shadow: unset;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .answered-survey-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font: normal normal 600 14px/18px Inter;
  }
}
