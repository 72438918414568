.track-overview {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 43px;
  margin-bottom: 38px;

  h3 {
    font: normal normal 600 24px/26px Inter;
    margin-bottom: 8px;
  }

  @media only screen and (max-device-width: 736px) {
    flex-wrap: wrap;
    margin-top: 24px;
  }
}

.track-description {
  font: normal normal normal 16px/21px Inter;
  max-width: 510px;
  @media only screen and (max-device-width: 736px) {
    text-align: center;
    font: normal normal normal 14px/18px Inter;
  }
}

.track-overview-image-wrapper {
  width: 294px;
  margin-right: 30px;
  @media only screen and (max-device-width: 736px) {
    width: 152px;
    margin-right: unset;
  }
  .Img {
    border-radius: 4px;
  }
}

.progress-bar-outer {
  width: 100%;
  height: 4px;
  background-color: #C5C5C5BF;
  margin-top: 20px;
  border-radius: 24px;
}

.progress-bar-inner {
  background-color: #4559D8;
  height: 4px;
  border-radius: 24px 0 0 24px;
}

.progress-bar-inner-full {
  border-radius: 24px;
}

.progress-summary {
  width: 246px;
}

.progress-summary-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4px;

  .overall {
    font: normal normal normal 12px/16px Inter;
  }

  .pct-indicator {
    font: normal normal 600 12px/14px Inter;
  }
}

.next-course-link-wrapper {
  margin-top: 20px;
}

.track-overview-data {
  @media only screen and (max-device-width: 736px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
