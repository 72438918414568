.course-management-panel-title {
  margin-top: 3.0rem;
}

.assigned-courses-row {
  width: 100vw;
  height: 100%;
  margin-left: -1.5rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  h3 {
    position: relative;
    left: 1.5rem;
  }
  .btn-success {
    margin-left: 3rem;
  }
}
