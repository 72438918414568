@import "../../common/styles/colors";
@import "../../common/styles/forms";

.pending-approval {
  @include login-background-mask;

  a {
  }
  h3 {
    font: normal normal 600 24px/26px Inter;
    letter-spacing: 0;
    color: #070707;
    margin-bottom: 12px;
  }
}

.pending-approval-inner {
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 768px) {
    width: 500px;
    margin-top: 60px;
    padding: 48px;
  }
  @media only screen and (max-width: 768px) {
    width: calc(100vw - 48px);
    margin-top: 30px;
    padding: 24px;
  }
}

.pending-approval-message {
  width: 100%;
  text-align: center;
  font: normal normal normal 16px/21px Inter;
  letter-spacing: 0;
  color: #000000DE;
}

.pending-approval-logo {
  padding-top: 20px;
  padding-bottom: 20px;

  img {
    height: 51px;
  }
}

.pending-approval-cta {
  text-align: center;
  font: normal normal normal 16px/21px Inter;
  letter-spacing: 0;
  color: #000000DE;
  margin-top: 12px;
}

.insta-logo {
  background-color: #425AD8;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-top: 12px;
  img {
    height: 20px;
    width: 20px;
    margin-top:10px;
    margin-left: 10px;
  }
}
.pa-shapes {
  position: absolute;
  bottom: -45px;
}
