.correct-question-card-wrapper {
    margin-top: 2.0rem;
    margin-bottom: 2.0rem;
}

.scroll-down {
    margin-top: 2.0rem;
    margin-bottom: 2.0rem;
}

