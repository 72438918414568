.login-form {
}

@mixin login-page-link {
  color: #4559D8;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}

.forgot-password-link {
  @include login-page-link;
  position: absolute;
  top: -3px;
  right: 0;
  font-size: 12px;
}

.create-account-link {
  @include login-page-link;
}

.login-form .checkbox {
  color: #fff;
}

.login-form .btn {
  width: 100%;
  margin-top: 50px;
}

.login-form .form-group:not(:first-child) {
  margin-top: 25px;
}

/* iphone 5, 5s portrait and landscape */
@media only screen and (max-device-width: 736px) {
  .forgot-password-link {
  }
}

.sign-in-button-wrapper {
  margin-top: 10px;
}

.login-form-error {
  color: #C30035;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-device-width: 736px) {
  .login-form-inner {
    width: 266.3px;
  }
}

@media only screen and (min-device-width: 736px) {
  .login-form-inner {
    width: 327.5px;
  }
}

.no-account-message {
  font-size: 14px;
  text-align: center;
  margin-top: 24px;
}
