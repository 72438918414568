.share-btn-modal{
  width: 500px;
  background-color: white;
  outline: none;
  padding-top: 1rem;
  padding-bottom: 2rem;
  border-radius: 5px;
}

.share-btn-modal-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .generator {
    width: calc(500px - 4rem);
  }
  .close-modal-btn-wrapper {
    button {
      font-size: 18px;
    }
    position: absolute;
    color: grey;
    right: 0;
    top: -1rem;
    &:hover {
      cursor: pointer;
      color: black;
    }
  }
}
