@import "../common";
.checkbox-root.Mui-checked {
  .MuiSvgIcon-root {
    fill: $styled-teal;
  }
  .MuiFormControlLabel-label {

  }
}
:not(.Mui-checked){
  .MuiSvgIcon-root {
    fill: $dark-grey-text;
  }
}

.select-deselect-all-controls {
  display: flex;
  margin-bottom: 10px;
  .catalyst-button-wrapper {
    margin-left: 10px;
    margin-right: 10px;
  }
}
