$widthOfStatusCard: 500px;

.signup-work-queue {
  width: 100%;
  height: 100%;
  padding-top: 10px;

  .sqw-loading-indicator {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .sqw-spinner {
      height: 100px;
      width: 100px;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-request-item {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  margin-bottom: 2.0rem;
  font-family: Inter, sans-serif;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  width: $widthOfStatusCard;
}

.request-status {
  margin-left: 50px;

  h4 {
    margin-top: unset;
  }
}

.swq-controls {
  display: flex;
  align-items: center;
  width: $widthOfStatusCard;
  justify-content: space-between;
  .catalyst-input-wrapper {
    flex-grow: 1;
  }

  .status-select-container {
    margin-left: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    width: 180px;
  }
}
