.employee-report-wrapper {
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
}

.employees-header {
}

.employee-list-wrapper .ReactVirtualized__Table__row {
  cursor: pointer;
}

.employee-report-wrapper .ReactVirtualized__Table__row {
  cursor: pointer;
}

.employee-list-inner {
  height: 100%;
}

.employee-list-header {
  flex-basis: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.employee-header-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add-employee-button {
}

.employee-list-wrapper {
  flex: 1;
}

@media only screen and (min-width: 768px) {
  .employee-list-wrapper, .employee-list-header {
    width: 75%;
    margin-right: auto;
    margin-left: auto;
  }
  .search-controls {
    width: 75%;
    margin: auto;
    .MuiTextField-root {
      width: 33%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .employee-list-header {
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 1rem;
  }
  .search-controls {
    padding-left: 25px;
    padding-right: 25px;
    .MuiTextField-root {
      width: calc(100% - 50px);
    }
  }
}

.countItem {
  text-align: center;
}

.Table {
  width: 100%;
  margin-top: 15px;
}

.headerRow {
  border-bottom: 1px solid #e0e0e0;
}

.evenRow,
.oddRow {
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;

  &:focus {
    outline: 0;
  }
}

.oddRow {
  background-color: #fafafa;
}

.headerColumn {
  text-transform: none;
}

.countHeader {
  text-align: center;
}

.noRows {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: #bdbdbd;
}

.completion-chart-container text {
  font-weight: bold;
  font-size: 16px;
}

.employee-list-wrapper {
}


.elh-wrapper {
  display: flex;
  flex-direction: column;
}

.export-button-wrapper {
  margin-right: 2rem;

  .btn {
  }
}

.search-controls {
  display: flex;
  margin-bottom: 1.5rem;
  padding-left: 0.5rem;

}

.employees-panel {
  height: 100%;
  display: flex;
  flex-direction: column;
}
