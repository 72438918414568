@import "../../../../../../../common/styles/colors";

.tag-selector {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.tag-selector-hidden {
  visibility: hidden;
}

.tag-item {
  border-radius: 18px;
  background-color: #383838;
  color: #FFFFFFDE;
  font: normal normal normal 14px/17px Inter;
  letter-spacing: 0;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag-item-selected {
  background-color: #425AD8;
}

.tag-selector-wrapper {
  margin-bottom: 20px;
}

.no-tags-please {
  height: 133px;
  font: normal normal 600 14px/17px Inter;
  letter-spacing: 0;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}


.tag-prompt {
  font: normal normal 600 14px/17px Inter;
  color: white;
  display: flex;
  justify-content: center;
  margin-top: 28px;
  margin-bottom: 12px;
}
