.answers-container {
  margin-top: 1.0rem;
  margin-bottom: 1.0rem;
}

.select-one {
  font-style: italic;
}

.question-card-answer-button {
  margin-top: 1.5rem;
}

.select-all-message {
  margin-bottom: 1.0rem;
  font-size: 14px;
  font-style: italic;
}

.answer-button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 14px;
}

.qc-incorrect-message {
  width: 100%;
  text-align: center;
  font: normal normal 400 12px/16px Inter;
  letter-spacing: 0.18px;
  color: #C30035;
  margin-top: 12px;
}
