@import "src/features/CardCourses/CardCourse/cards/CatalystFormCard/common/styles";
.cat-select__control {
}

.cat-select-container {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 16px;
  height: 44px;

  .cat-select__option--is-selected {
    background-color: white;
    color: #000000DE;
  }

  .cat-select__control {
    @include catalyst-input;
    box-shadow: none;

    .cat-select__placeholder {
      height: 18px;
    }

    &:focus {
      border-bottom: 2px solid #425AD8;
    }

    &:hover {
      border-bottom: 2px solid #425AD8;
    }

    .css-1g6gooi {
      margin: 0;
      padding: 0;
    }
  }

  .cat-select__value-container {
    padding: 0;
    min-height: unset;
    height: 18px;
  }
  .cat-select__value-container--is-multi {
    height: 24px !important;
  }

  .cat-select__multi-value {
    height: 24px;
  }

  .cat-select__multi-value__remove {
    color: #00000099;
    position: relative;
    top: -1px;
  }

  .cat-select__indicator-separator {
    display: none;
  }

  .cat-select__indicator {
    padding: 0;

    span {
      font-size: 10px;
      color: #00000099;
    }
  }

  .cat-select__input {
    height: 18px;
  }

  .cat-select__menu {
    margin: 0;
    border-radius: 0 0 4px 4px;
    font-size: 14px;
    .option {
      display: flex;
      align-items: center;
      .cat-checkbox {
        border: 2px solid #00000099;
        height: 16px;
        width: 16px;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          display: none;
        }
      }
      .is-selected {
        span {
          width: 100%;
          height: 100%;
          color: white;
          left: 2px;
          position: relative;
          display: block;
        }
        border: none;
        height: 18px;
        width: 18px;
        background-color: #425AD8;
      }
    }
    .option-checkbox-wrapper {
      padding: 3px;
      margin-right: 7px;
    }
  }

  .cat-select__menu-list {
    padding: 0;
  }

  .cat-select__option--is-focused {
    background: #425AD821 0% 0% no-repeat padding-box;
  }

  .cat-select__multi-value {
    //height: 18px;
    margin: 0;
    margin-right: 4px;
    background-color: #0000001F;
    padding-left: 5px;
    height: 24px;
  }
  .cat-select__multi-value__label {
    padding: 0;
  }

  .multi-select-label {
    margin-right: 5px;
  }
}

.cat-select-label {
  @include catalyst-label;
}
