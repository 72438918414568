@import "../../common/styles/fonts";
@import "../../common/styles/forms";

.forgot-password-form-wrapper {
  @include login-form-wrapper;
}

.forgot-password-background {
  @include login-background-mask;
}

.forgot-password-logo {
  margin-bottom: 24px;

  img {
    height: 65px;
    width: auto;
  }
}
@media only screen and (max-device-width: 736px) {
  .forgot-password-form-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media only screen and (min-device-width: 736px) {
}
.forgot-password-shapes {
  position: absolute;
  bottom: -45px;
}

.reset-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  .login-link-wrapper {
    a {
      color: #4559D8;
    }
  }
  .ri-block {
    margin-bottom: 10px;
  }
}

