video.Video {
    object-fit: cover;
    object-position: center;
    flex: 1;
    flex-basis: 0%;
    max-height: 100%;
    background-color: #070707;
    border-radius: 3px;
}

.Video--aspectRatio {
    flex: 0;
    height: 0;
    position: relative;

}
.Video--aspectRatio > video:focus {
    outline: none;
}

.Video--aspectRatio > video {
    background-color: #070707;
    display: block;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    object-fit: contain;
    object-position: center;
}

.Video--loading {
    background-color: #d8d8d8;
}
