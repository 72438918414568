.card-course-card {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  margin-bottom: 2.0rem;
  @media only screen and (min-width: 600px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 55ch
  }
}

.ccc-hide-box-shadow {
  /* Add shadows to create the "card" effect */
  box-shadow: unset;
}

.card-course-container {
  margin-top: 1.5rem;
  padding: 2px 16px;
}

.card-course-media {
  border-radius: 5px 5px 0 0;
}

.card-spacer {
  height: 1.5rem;
}
