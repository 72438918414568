.image-button-card-img-wrapper {
  position: relative;
}

.confirmation-overlay {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.80;
}

.success-message {
  color: white;
  font-weight: bold;
}
