.update-password-wrapper {
    height: 100%;
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 2%;
    text-align: center;
    background-color: white;
}

.update-password-form-wrapper {
    height: 100%;
    width: 100%;
}
