@import "../../../common/styles/colors";

.header-message {
  float: left;
  color: white;
  position: relative;
  top: 20px;
  left: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow:visible;
  @media screen and (max-width: 1000px) {
    display: none;
  }
  @media screen and (min-width: 1000px) {
    max-width: 350px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 500px;
  }
}

.admin-navigation {
  /*max-width: 100vw;*/
  @media screen and (max-width: 768px) {
    height: 70px;
  }
  height: 85px;
  margin-bottom: unset;
}

.navbar-brand {
  padding-top: 5px;
  padding-bottom: 5px;
}

.navbar-brand-wrapper {
  outline: none;
}

.brand-with-text {
  display: flex;
  flex-direction: row;
}

.navbar-collapse {
  overflow-y: scroll !important;
  max-height: calc(100vh - 70px) !important;
}

.admin-navigation {
  @media screen and (max-width: 768px) {
    .dropdown {
      a {
        color: white;
        &:focus {
          background-color: black;
          outline: none;
          color: white;
        }
        &:hover {
          background-color: black;
          outline: none;
          color: white;
        }
      }
    }
  }
}

.jump-to {
  margin-right: 5px;
}

.hidden-logo {
  visibility: hidden;
}

.navbar-nav {
  background-color: #070707;
  @media screen and (max-width: 768px) {
    top: -10px;
    position: relative;
  }
}

.navbar-container {
  width: 100%;

  @media screen and (min-width: 768px) {
    padding-left: 34px;
    padding-right: 48px;
  }
}
.switcher-component-list-item {
  @media screen and (min-width: 768px) {
    display: none !important;
  }
}

