.state-picker {
  margin-top: 5px;
  display: flex;
  margin-bottom: 15px;
  justify-content: left;
}

.state-select {
  flex-grow: 1;
  max-width: 400px;
}

@media (max-width: 500px) {
  .state-picker  {
    font-size: 16px ;
  }
}
