.bd-course-overview {
  display: grid;
  grid-template-columns: 30px 1fr 200px;
  border-radius: 5px;
  background-color: #313131;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border: 1px solid white;
  margin-bottom: -1px;

  .budtenders-completed-count {
    padding-left: 5px;
    font-weight: bold;
  }

  span {
    position: relative;
    top: 2px;
  }
}

.overview-result {
  font-family: Inter, sans-serif;
  padding: 15px 10px;
  min-height: 100px;
  display: grid;
  grid-template-columns: 1fr 200px 1fr 150px;

  a {
    &:focus {
      outline: none;
    }
  }

  .overview-result-header-item {
    font-weight: bold;
  }

  .overview-result-row-item {
    border-bottom: 1px solid lightgrey;
    height: 47px;
    display: flex;
    align-items: center;
  }

  .missing-comments {
    font-style: italic;
  }

  .overview-result-comments {
    width: 700px;

    :first-child {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }
  }

  .overview-result-response-value {
    padding-left: 10px;
  }

}

.next-co-page-btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;

  button {
    font-family: Inter, sans-serif;
  }
}
