@import "src/common/styles/colors";
@import "src/common/styles/breakpoints";
@import "src/common/styles/buttons";

.up-next-component {
  position: relative;
  bottom: 0;
  left: 0;
  margin-bottom: -85px;
  width: 100vw;
  background: $zt-black 0 0 no-repeat padding-box;
  display: flex;
  justify-content: center;
  padding-bottom: 24px;

  margin-left: calc(-1 * (1.5rem + (100vw - 70ch) / 2));
  @media only screen and (max-device-width: $phoneBreakpoint) {
    margin-left: -3rem;
    padding-left: 20px;
    padding-right: 20px;
  }

  .next-course-footer {
    width: 100%;
    max-width: 401px;

    .next-text {
      color: #FFFFFF;
      padding-top: 22px;

      .nt-static {
        font: normal normal 600 10px/16px Inter;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        margin-bottom: 4px;
      }

      .nt-course-title {
        font: normal normal 600 16px/21px Inter;
        letter-spacing: 0;
      }
    }

    .next-up-details {
      display: flex;
      flex-direction: row;
      padding-top: 20px;
      padding-bottom: 18px;
    }

    .next-item-poster {
      margin-right: 20px;
      flex-grow: 0;
      flex-shrink: 0;

      img {
        border-radius: 4px;
        max-height: 84px;
        height: 100%;
      }
    }

    .next-course-btn {
      display: flex;
      justify-content: center;
      width: 100%;
      @include purple-btn;
    }
  }

  .course-not-found {
    width: 100%;
    max-width: $desktopMaxBtnWidth;
    padding-top: 32px;
    padding-bottom: 32px;
    @media only screen and (max-device-width: $phoneBreakpoint) {
      padding: 20px 24px;
    }

    .primary-btn {
      @include purple-btn;
      margin-bottom: 12px;

    }

    .secondary-btn {
      border: 1px solid #FFFFFF80;
      max-width: $desktopMaxBtnWidth;

      .catalyst-button-wrapper {
        .catalyst-button-contained {
          background-color: unset;
        }
      }
    }

    .unearned-rewards-header {
      font: normal normal 600 18px/21px Inter;
      margin-bottom: 12px;
      letter-spacing: 0;
      color: #FFFFFF;
      text-align: center;
    }
  }

}

