@import "../../../common/styles/fonts";
@import "../../../common/styles/forms";

.rml-container {
  @include login-background-mask;
}

.rml-form-wrapper {
  @include login-form-wrapper;
  margin-bottom: 100px;
  margin-top: 133px;
  .welcome-message {
    h5 {
      text-align: center;
    }
  }
}

.rml-info {
  font: normal normal normal 16px/21px Inter;
  letter-spacing: 0;
  color: #000000DE;
  margin-bottom: 24px;
}

.magic-link-shapes {
  position: absolute;
  bottom: -45px;
}

@media only screen and (max-device-width: 736px) {
  .rml-form-wrapper {
    width: calc(266.3px + 48px);
  }
}

@media only screen and (min-device-width: 736px) {
  .rml-form-wrapper {
    width: calc(327.5px + 48px);
  }
}
