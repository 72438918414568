@import "common";

$background-grey: #EEF0F4;

.dispensary-track-assignment {
  min-height: 0;
  height: 100%;
  display: flex;
  flex-direction: row;
  font-family: "Open Sans", sans-serif;
  background-color: $background-grey;
  flex: 1;
  padding-left: 10rem;
  padding-right: 10rem;
}

.select-a-track-placeholder {
  @include track-panel;
  margin-left: 1.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
  font-style: italic;
}

.dta-spinner {
  align-self: center;
  margin:auto;
}
