@import "../../common/styles/fonts";
@import "../../common/styles/forms";

.login-form-wrapper {
  @include login-form-wrapper;
  margin-bottom: 100px;
  margin-top: 133px;
}


.login-background {
  @include login-background-mask;
}

.login-logo {
  position: absolute;
  top: 16px;

  img {
    height: 97px;
  }
}

.login-shapes {
  position: absolute;
  bottom: -45px;
}

.hb-announcement {
  color: #fff;
  font-size: 14px;
  position: absolute;
  bottom: -90px;
  
strong {
  color: #FF35D2;
  font-weight: 800;
}
}

@media only screen and (max-device-width: 736px) {
  .login-form-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media only screen and (min-device-width: 736px) {
}
