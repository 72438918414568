@import "../../common";

.add-track-wizard {
  font-family: "Open Sans", sans-serif;
  background-color: white;
  height: 900px;
  padding: 3rem 15rem 5rem;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  overflow-y: auto;
  position: relative;
  width: calc(749.5px + 30rem);

  .spinner {
    top: -5rem;
  }

  h1 {
    font-weight: bold;
  }

  input {
    width: 40rem;
  }
}

.atw-filter-tracks {
  margin-top: 3rem;

  .MuiInput-input {
    font-family: "Open Sans", sans-serif;
    height: 3rem;
    width: 50rem;
    font-size: 20px;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-bottom: 1rem;
  }

  .MuiInput-underline:before {
    border-bottom: 3px solid $styled-grey;
  }

  .MuiInput-underline:after {
    border-bottom: 3px solid $styled-teal;
  }
}

.atw-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.at-list-wrapper {
  margin-top: 4rem;
  border: 3px solid $styled-grey;
  border-radius: 20px;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  flex: 1;
  min-height: 0;
  width: 100%;
  overflow-y: hidden;
}

.at-list-inner {
  flex: 1;
  min-height: 0;
  overflow-y: auto;
  max-height: 100%;

  &::-webkit-scrollbar {
    width: 1.3rem;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2B2A2A;
    border-radius: 10px;
    border: 3px solid $styled-grey;
  }
}

.active-item-wizard {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}


.atw-btn-bar {
  justify-content: flex-start;
  margin-top: 4rem;
  width: 100%;
  display: flex;
}

.atw-role-selector {
  width: 100%;
}

.wizard-message {
  color: $dark-grey-text;
  max-width: 500px;
  align-self: flex-start;
  font-size: 20px;
  font-weight: 500;

  &.rs {
    margin-bottom: 1.5rem;
  }

  &.mc {
    margin-top: 2rem;
  }
}

.atw-cancel-button {
  position: absolute;
  top: 2rem;
  right: 2rem;

  span {
    color: #2B2A2A;
    font-size: 25px;
  }
}

.atw-back-btn {
  @include zt-button;
  margin-right: 1rem;
  color: $styled-teal;
  background-color: white;
  border: solid 3px $styled-teal;
}

$content-width: 600px;

.atw-active-item-card {
  margin-top: 3.5rem;
  padding: 4.5rem;
  width: $content-width;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 10px;
}

.atw-detail-header {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-bottom: 3px solid $styled-grey;
  margin-bottom: 4rem;
  width: $content-width;
}

.atw-img {
  width: $img-width;
  margin: 2rem;
  .Img {
    border-radius: 50%;
  }
}

.atw-details {
  margin-left: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.atw-title {
  font-weight: bold;
  font-size: 36px;
  flex-grow: 0;
}

.modal-overlay {
  .react-select-container {
    z-index: 10001;
  }
}
