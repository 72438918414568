@import "fonts";

$maxMobileSize: 768px;
@mixin login-form-wrapper {
  border-radius: 1px;
  margin-top: 87px;
  position: relative;
  background-color: #FFFFFF;
  padding: 36px 48px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .catalyst-text-input {
    width: 327.5px;
  }
  @include modern-zoltrain-font;
  @media only screen and (max-device-width: $maxMobileSize) {
    .catalyst-text-input {
      width: 266.3px;
    }
  }

  .welcome-message {
    h5 {
      font-weight: 500;
      color: #070707;
      font-size: 24px;
      letter-spacing: 0;
      margin-bottom: 24px;
      margin-top: 0;
    }
  }

  .catalyst-input-wrapper {
    position: relative;
  }
}

@mixin login-background-mask {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: #070707;
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  bottom: 0;
  overflow-y: auto;
}
