.users-wrapper {
    background-color: #fff;
    padding: 30px;
}

.users-wrapper h3{
    font-family: 'Brandon-med', sans-serif;
    margin-bottom: 30px;
    text-transform: uppercase;
    display: inline-block;
}

.users-wrapper .users-search-wrapper {
    margin-bottom: 20px;
}

.users-list {
    margin: 0;
    padding: 0;
}


.users-table thead th {
    cursor: pointer;
}

.users-table thead th span {
    font-size: 10px;
}
.users-table thead th span.light-gray-sort-icon {
 color: lightgray;
}
.users-table tbody .users-user-row {
    cursor: pointer;
}

.users-table tbody .users-user-row td button {
    margin-left: 5px;
}

.users-search-wrapper .page-count {
    position: relative;
    margin-left: 10px;
}

.users-search-wrapper .user-count {
    font-weight:bold;
}
/*.users-table {*/
    /*display: block;*/
    /*width: 100%;*/
/*}*/

/*.users-table-details {*/
    /*display: block;*/
    /*width: 100%;*/
    /*padding: 20px 30px;*/
    /*float: left;*/
    /*background-color: ghostwhite;*/
    /*border-top: 1px solid grey;*/
    /*border-bottom: 1px solid grey;*/
/*}*/
/*.users-table-heading, .users-table-body  {*/
    /*display: table-header-group;*/
    /*width: 100%;*/
    /*float: left;*/
    /*clear: both;*/
/*}*/


/*.users-table-body {*/
    /*display: table-row-group;*/
/*}*/


/*.users-table-heading .users-table-cell {*/
    /*font-weight: bold;*/
    /*margin-bottom: 10px;*/
    /*border-bottom: 2px solid grey;*/
/*}*/


/*.users-table-row, .users-table-details-row {*/
    /*width: 100%;*/
    /*clear: both;*/
    /*cursor: pointer;*/
/*}*/

/*.users-table-row.selected {*/
    /*background-color: lightskyblue;*/
/*}*/

/*.users-table .users-table-body .users-table-row-wrapper:nth-child(odd) {*/
    /*background-color: #f9f9f9;*/
/*}*/
/*.users-table-details-row {*/
    /*float: left;*/
/*}*/

/*.users-table-row:hover {*/
    /*background-color: #f5f5f5;*/
/*}*/

/*.users-table-row.selected:hover {*/
    /*background-color: lightskyblue;*/
/*}*/

/*.users-table-details-row:hover {*/
    /*background-color: #f5f5f5;*/
/*}*/

/*!*.users-table-row:not(.users-table-details-row):nth-of-type(odd) {*!*/
    /*!*background-color: #f9f9f9;*!*/
/*!*}*!*/

/*.users-table-cell{*/
    /*float: left;*/
    /*display: table-column;*/
    /*width: 12.5%;*/
    /*padding: 10px;*/
    /*line-height: 1.42857143;*/
    /*vertical-align: top;*/
    /*background-color: inherit;*/
/*}*/

/*.users-table-cell.action-cell {*/
    /*padding: 5px;*/
    /*vertical-align: middle;*/
/*}*/


/*.users-table-details .users-table-cell {*/
    /*width: 50%;*/
    /*vertical-align: middle;*/
/*}*/
/*.users-table-details .users-table-cell.action-cell {*/
    /*padding: 5px;*/
/*}*/

/*.users-table-details .users-table-cell.action-cell button {*/
    /*margin-left: 10px;*/
/*}*/

/*@media (min-width: 1200px)*/
/*and (max-width: 1600px) {*/

    /*.users-table-cell.email-cell {*/
        /*width: 20.5%;*/
    /*}*/

    /*.users-table-cell.tests-cell {*/
        /*width: 9.5%;*/
    /*}*/

    /*.users-table-cell.active-cell {*/
        /*width: 5.5%;*/
    /*}*/

    /*.users-table-cell.lessons-cell {*/
        /*width: 14.5%;*/
    /*}*/
/*}*/
