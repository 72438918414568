.field-label {
  color: #EEEEEE;
}

.dispensary-sign-up-form {
  @media only screen and (max-width: 768px) {
    input {
      width: 300px;
    }
    .submit-wrapper {
      display: flex;
      justify-content: center;
    }
  }
  @media only screen and (min-width: 768px) {
    .submit-wrapper {
      display: flex;
      justify-content: flex-start;
    }
  }
}
