@import "src/common/styles/buttons";
@import "src/common/styles/breakpoints";

$footerBackground: #303030CC;
.next-reward-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  background: $footerBackground 0% 0% no-repeat padding-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  h3 {
    font: normal normal 600 18px/21px Inter;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-top: 0;
    margin-bottom: 12px;
  }
  @media only screen and (max-device-width: $phoneBreakpoint) {
    padding: 24px 20px;
  }
  @include purple-btn;
}

.reward-overlay-up-next.up-next-component {
  position: fixed;
  bottom: 0;
  left: 0;
  margin-left: 0;
  margin-bottom: 0;
  background-color: $footerBackground;
}
