.password-section {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 14pt;
  .password-field-label {
    margin-bottom: 5px;
    color: #5e6c83;
    font-weight: bold;
  }
  font-family: Inter, sans-serif;
}

