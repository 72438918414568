@mixin modern-zoltrain-font {

  font-family: 'Inter', sans-serif;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0;
  h1, h2, h3, h4, {
    font-weight: 600;
  }
}
