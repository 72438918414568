@import "../../../common/styles/colors";

.main-navigation.navbar {
    background-color: $zt-black;
}

.main-navigation .nav>li>a {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
}
.main-navigation .nav>li.active > a {
    background-color: $zt-black;
}
.main-navigation  .nav .open>a,
.main-navigation  .nav .open>a:focus,
.main-navigation  .nav .open>a:hover {
    background-color: $zt-black;
    border-color: $zt-black;
}
.main-navigation .nav>li>a:focus, .main-navigation .nav>li>a:hover {
    background-color: $zt-black;
}

.main-navigation .navbar-nav>li>a {
    padding-top: 35px;
    padding-bottom: 30px;
    font-size: 14px;
}

.main-navigation .navbar-brand>img {
    max-height: 100%;
    max-width: 100%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top:5px;
}


.main-navigation .navbar-brand {
    height: 80px;
}

.main-navigation .navbar-toggle {
    background-color: white;
    margin-top: 18px;
}

.main-navigation .navbar-toggle .icon-bar{
    background-color: $zt-black;
}

/* iphone 5, 5s portrait and landscape */
@media only screen
and (max-device-width : 768px) {
    .main-navigation .navbar-brand {
        height: 70px;
    }

    .main-navigation .navbar-brand img{
        margin-top: 0;
    }
}
