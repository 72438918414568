@import "../../../common/styles/colors";

.profile-dropdown {
}

.profile-dropdown .logged-user {
    font-weight: normal !important;
}


.profile-dropdown.dropdown.open .dropdown-menu{
    float: left;
    width: 100%;
    background-color: $zt-black;
}
.profile-dropdown .dropdown-menu >li {
    display: block;
}
.profile-dropdown .dropdown-menu >li > a{
    color: white;
}

.profile-dropdown .dropdown-menu >li > a:hover,
.profile-dropdown .dropdown-menu >li > a:focus {
    background-color: $zt-black;
    background-image: none;
}

.profile .nav-link {
    color: white;
}
