@import "../../../../../../common/styles/colors";

$select-button-wrapper-height: 97px;
$background-grey: #F5F5F5;

.incentive-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(5, 5, 5, 0.98);
  z-index: 10000;
  font-family: Roboto, sans-serif;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 70px;
  @media only screen and (max-width: 768px) {
    padding-top: 44px;
  }

  * {
    min-height: 0;
    min-width: 0;
  }
}

.incentive-modal-content {
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow: hidden;
  outline: none;

  @media only screen and (min-width: 768px) {
    width: 520px;
    max-height: 700px;
  }
  @media only screen and (max-width: 768px) {
    width: 337px;
    max-height: 547px;
  }

  h2 {
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
  }
}

.incentive-modal-inner {
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.award-alert-body {
  background-color: $background-grey;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
  flex-direction: column;
  align-items: center;
}

$confirm-selection-button-wrapper-height: 72px;
.reward-summary {
  overflow-y: scroll;
  background-color: $background-grey;
  height: 100%;

  .sample-option {
    cursor: default;
  }

  h3 {
    font: normal normal normal 22px/25px Inter;
    letter-spacing: 0px;
    color: #070707;
    opacity: 1;
    margin-top: 0px;
  }
}

.reward-overview-section {
  display: flex;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .sample-option {
    width: 305px;
  }
}

.reward-overview-header {
  flex-shrink: 0;
  flex-grow: 0;
}

.sample-wrapper {

  @media only screen and (min-width: 768px) {
    padding-left: 108px;
    padding-right: 108px;
  }
  @media only screen and (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.sample-option {
  outline: none;
  flex-shrink: 0;

  h4 {
    text-align: center;
    margin-top: 0;
    font: normal normal 600 18px/21px Inter;
    letter-spacing: 0;
    color: #070707;
    opacity: 1;
  }

  margin-bottom: 16px;

  display: flex;
  flex-direction: column;
  border: 1px solid white;

  padding: 16px 16px 8px 8px;
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 1px 3px #0000002B;
  border-radius: 4px;
}

.reward-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selected-sample-option {
  border: 1px solid #070707;
}

.select-message {
  flex-grow: 0;
  font: normal normal 600 10px/16px Inter;
  letter-spacing: 1.25px;
  color: #000000AB;
  text-transform: uppercase;
}

.reward-img-wrapper {
  flex-grow: 1;
  flex-basis: 0;
  max-height: 100%;
  overflow: hidden;
}

.sample-text {
  flex-grow: 1;
  flex-basis: 0;
  margin-left: 12px;
  overflow: hidden;
}

.reward-button-wrapper {
}

@mixin reward-footer {
  flex-direction: column;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
}

.confirm-reward-footer {
  @include reward-footer;
  .receive-updates-from-brand-wrapper {
    margin-top: 15.5px;
    margin-bottom: 15.5px;
    min-height: unset;
    min-width: unset;
    label {
      div:first-child {
        min-width: unset;
      }
    }
  }
}

.select-reward-footer {
  height: $select-button-wrapper-height;
  @include reward-footer;
}

.reward-header {
  flex-grow: 0;
  flex-shrink: 0;

  h3 {
    text-align: center;
    font: normal normal normal 22px/25px Inter;
    letter-spacing: 0;
    margin-top: 32px;
    color: #070707;
  }
}

.sample-list {
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  flex-direction: column;
  align-self: stretch;

  h4 {
    align-self: center;
  }
}

.selected-reward-title {
  text-align: center;
  font: normal normal normal 14px/20px Inter;
  letter-spacing: 0;
  color: #000000DE;
  opacity: 1;
  margin-bottom: 8px;
}

.campaign-qualification-status {
  margin-bottom: 10px;
}

.request-additional-info-form {
  width: 100%
}

.raif-fields {
  padding: 20px;
}

.raif-buttons {
  display: flex;
  width: 100%;
  .back-btn {
    margin-right: 10px;
    flex: 1;
  }
  .raif-submit-btn {
    flex: 1;

  }
}

