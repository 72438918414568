@import "../../../common/styles/colors";
@import "../../../common/styles/buttons";

.generator {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.generated-input {
  flex: 1;
  margin-top: 1rem;

  .MuiFormControl-root {
    width: 100%;
  }
}

.copy-generated-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.successful-copy-message {
  margin-left: 1rem;
}

.generate-and-copy-button {
  @include bcli-button;
}
