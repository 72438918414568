.loading {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #fa6b5d;
    border-radius: 50%;
    background-color: #fff;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    -webkit-animation: spin 1s linear infinite;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
}

.loading-sm {
    width: 30px;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.loading-xs {
    width: 15px;
    height: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
