@import "../../../../../common/styles/colors";

.track-item {
  height: 64px;
  margin-bottom: 12px;
  //background: #0000000F 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  font: normal normal normal 16px/21px Inter;
  display: flex;
  align-items: center;
  max-width: 650px;
  background-color: #0000000F;
}

.track-item-title {
  font: normal normal normal 16px/21px Inter;
}

.track-item-image {
  width: 64px;
  border-radius: 4px 0 0 4px;
  background-color: $zt-black;
}

.track-item {
  .track-item-right-group {
    margin-left: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    margin-right: 12px;

    span {
      color: #425AD8;
    }
  }
}

.track-item-courses-title {
  font: normal normal 600 18px/21px Inter;
  margin-top: 0;
}

.course-started-dash {
  margin-right: 5px;
}
