img.Img {
    object-fit: cover;
    object-position: center;
    flex: 1;
    flex-basis: 0%;
    max-height: 100%;
    border-radius: 3px;
}

.Img--aspectRatio {
    flex: 0;
    height: 0;
    position: relative;
}

@media only screen and (min-width: 768px) {
    .Img--aspectRatio {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.Img--aspectRatio > img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    object-fit: contain;
    object-position: center;
}

.Img--loading {
}
