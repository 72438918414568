.correct-question-card-wrapper {
}

.question {
}

.answer {
}

.scroll-down {
  margin-top: 2.0rem;
  margin-bottom: 2.0rem;
  width: 100%;
  text-align: center;
}

.incorrect-banner {
}

.question-card-wrapper {
  padding: 20px 16px 2px;
}

.qc-header {
  text-transform: uppercase;
  font: normal normal 600 10px/16px Inter;
  letter-spacing: 1.25px;
  color: #000000AB;
  margin-bottom: 8px;
}

.qc-question-text {
  font: normal normal 600 16px/20px Inter;
  letter-spacing: 0;
  color: #070707;
}

.qc-correct-answer-text {
  background: #425AD814 0 0 no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  font: normal normal 400 12px/16px Inter;
  letter-spacing: 0.18px;
  color: #000000BF;
  padding: 8px;
  margin-top: 12px;
}
