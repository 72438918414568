@import "../../../common/styles/colors";

.magic-link-processing {

  height: 100%;
  width: 100%;
  max-width: 70ch;
  margin: auto;
  font-size: 18px;
  padding: 1.5rem 1.5rem 85px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mlp-initial {
  opacity: 0;
}

.mlp-thinking {
  transition: opacity 1s;
  opacity: 1;
}

.magic-link-auth-container {
  font-family: Open Sans, sans-serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 60%;
  a {
    color: $zt-teal-next;
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 2rem;
  }
}
