@import "../../../styles/colors";

.form-group button[type="submit"] {
  background-color: $zt-teal;
  color: #fff;
  text-transform: uppercase;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  opacity: 0.9;
}

.form-group button[type="submit"]:hover, button[type="submit"]:focus {
  opacity: 1;
}
