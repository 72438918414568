.multi-card-wrapper {
  width: calc(100% + 10px);
  max-width: 630px;
  position: relative;

  .slick-list {
    padding-left: 5px;
    padding-right: 5px;
  }

  .card-course-card {
    flex: 1 0 100%;
  }

  .slick-dots li button:before {
    font-size: 14px;
    line-height: 14px;
    margin: 4px;
  }

  .slick-track {
    display: flex;
    align-items: stretch;
  }

  .slick-slide {
    height: unset;
  }

  .slick-slide > div:first-child {
    height: 100%;
    display: flex;
  }
}

.multi-card-wrapper.mcw-dots {
  margin-bottom: 50px;
}


@media only screen and (min-width: 768px) {
  .multi-card-wrapper {
    left: 1.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .multi-card-wrapper {
    left: -0.5rem;

    .card-course-card {
      max-width: calc(100% - 10px);
    }
  }
}


.slider-index-indicator {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-style: italic;
  margin-bottom: 1.5rem;
}
