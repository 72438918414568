@mixin catalyst-input {
  background: #0000000D 0 0 no-repeat padding-box;
  border-radius: 1px;
  border: none;
  border-bottom: 1px solid #0000006B;
  padding: 12px 16.5px;
  &:focus {
    border-bottom: 2px solid #425AD8;
    outline: none;
  }
}

@mixin catalyst-label {
  line-height: 16px;
  font: normal normal 500 12px/16px Inter;
  letter-spacing: 0.18px;
  color: #000000BF;
}
