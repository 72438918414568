.need-help-footer {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48.5px;
  border-top: 1px solid rgba(255, 255, 255, .25);
  a {
    font-size: 14px;
    color: #FFFFFFDE;
  }
  flex-shrink: 0;
  flex-grow: 0;
}
