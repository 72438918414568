.brand-dispensary-detail-row {
  padding-left: 1rem;
  padding-top: 2rem;
  height: 100%;
  display: flex;
  flex-direction: row;
  h2 {
    margin-top: 10px;
  }
}


.brand-dispensary-address-details {

}

.detail-header {
  margin-bottom: 5px;
  font-weight: bold;
  font-style: italic;
  font-size: 18px;
}
