
html {
    height: 100%;
    min-height: 100%;
    position: relative;
}

#root {
    height: 100%;
    min-height: 100%;
}

/*#root, .public-app, .public-app > .container-fluid {*/
/*}*/
.public-app {
    padding-top: 84px;
}
