@import "../../../../../../../common/styles/colors";

$grey-background: #0000000D;

@mixin common-answer-input {
  -webkit-appearance: none;
  appearance: none;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  &:focus {
    outline: none !important;
  }
}

.answer-radio {
  @include common-answer-input;
  background-color: black;
  mask: url('../../../../../../../assets/images/radio-unselected.svg') 0 center no-repeat;
  outline: none;
}

.answer-radio:checked {
  background-color: white;
  mask: url('../../../../../../../assets/images/radio-selected.svg') 0 center no-repeat;
}

.answer-checkbox {
  @include common-answer-input;
  background-color: black;
  mask: url('../../../../../../../assets/images/checkbox-unselected.svg') 0 center no-repeat;
}

.answer-checkbox:checked {
  background-color: white;
  mask: url('../../../../../../../assets/images/checkbox-selected.svg') 0 center no-repeat;
}

.answer-selected {
  background-color: #425AD8;
  color: white;
}

$incorrect-text-color: #C30035;
.answer-incorrect {
  background-color: #FC4D5E14;
  color: $incorrect-text-color;

  .answer-radio {
    background-color: $incorrect-text-color;
  }
  .answer-checkbox:checked {
    background-color: $incorrect-text-color;
  }
}

.answer-label {
  width: 100%;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 10px;
  font: normal normal normal 14px/20px Inter;
  line-height: 18px;
  margin-bottom: unset;

  &:hover {
    cursor: pointer;
  }
}

.md-radio:not(.answer-selected):not(.answer-incorrect) {
  background-color: $grey-background;
  color: #000000DE;
}

.md-radio {
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  input {
    margin-top: unset;
  }

  p {
    margin-bottom: unset;
    position: relative;
    top: 1px;
  }


  padding: 9px 10px 10px;
  border: 1px solid white;

  &:hover {
    border: 1px solid #0000006B;
    cursor: pointer;
  }
}

.md-radio-single {
  border-radius: 18px;
}

.md-radio-multi {
  border-radius: 4px;
}
