@import "src/common/styles/colors";

.pcbs-select-container {
  outline: none;
  font: normal normal 600 14px/16px Inter;
  border: none;
  border-radius: 1px;
  letter-spacing: 0.21px;
  height: 40px;

  @media screen and (min-width: 768px) {
    width: 199px;
  }
  @media screen and (max-width: 767px) {
    margin-left: 19px;
    margin-right: 19px;
    margin-top: 9px;
  }

  span {
    color: white;
    margin-right: 5px;
  }

  .pcbs-select__control {
    background-color: $zt-offlback;
    border: none;
    border-radius: 1px;
  }


  .pcbs-select__menu {
    color: $zt-black;
    font: normal normal 600 14px/16px Inter;
    letter-spacing: 0.21px;
  }

  .pcbs-select__single-value {
    color: white;
  }

  .pcbs-select__indicator-separator {
    display: none;
  }
}

.header-message {

  .pcbs-select-container {
    margin-top: -7px;
  }
}
