.catalyst-button-wrapper {
  width: 100%;
}

@mixin catalyst-button-common {
  width: 100%;
  border-radius: 1px;
  height: 41px;
  border: none;
  font: normal normal 600 14px/16px Inter;
  letter-spacing: 0.21px;
}

.catalyst-button-contained {
  @include catalyst-button-common;
  background: #303030 0 0 no-repeat padding-box;
  color: white;
  &:disabled{
    background-color: #3030304D;
  }
}

.catalyst-button-outlined {
  @include catalyst-button-common;
  border: 2px solid #07070780;
  background-color: white;
  color: #070707;
}

.catalyst-button-text {
  @include catalyst-button-common;
  background-color: white;
  color: #070707;
}
