.add-employee-wrapper {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-employee-error {
  border-radius: 4px;
  background-color: lightcoral;
  margin-top: 20px;
  padding: 20px;
  max-width: 700px;
  color: white;
  width: 100%;
}
