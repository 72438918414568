.multi-image-wrapper {
  .slick-dots li button:before {
    font-size: 14px;
    line-height: 14px;
    margin-top: 1rem;
  }

  .slick-dots li {
    margin: 4px;
  }

  .Img {
    outline: none;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-slide > div:first-child {
    height: 100%;
  }

  .post-content {
    padding-top: 1.5rem;
  }
}

.miw-empty {
  padding-bottom: 3rem;
}
