.save-dispensary-form-wrapper {
  h3 {
    font: normal normal normal 21/21px Inter;
  }
}

.dispensary-location-field {
  .catalyst-input-wrapper {
    top: -5px;
    position: relative;
  }
}

.create-dispo-controls {
  display: flex;
  flex-direction: row;
  .brand-selector {
    flex-grow: 1;
    margin-left: 10px;
  }
}

.submitButton {
  margin-top: 5px;
}


.admins-section {
  .admins-header {
    font-size: 18px;
    font-family: Inter, serif;
    margin-bottom: 10px;
  }

  .admins-fields {
    padding-left: 30px;
    .MuiButtonBase-root {
      position: relative;
      span {
        color: #070707;
      }
      top: -10px;
    }
  }

  .admin-field {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    position: relative;
    .MuiButtonBase-root {
      position: absolute;
      left: -50px;
      top: 0;
      span {
        color: #070707;
      }
    }
  }
}
