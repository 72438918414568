input.form-control:not([type="submit"]), input.form-control:not([type="radio"]) {
  background-color: #EEE;
  border-radius: 0;
  border: 1px solid #eee;
  height: auto;
  padding: 20px;
  box-shadow: none;
}

.has-error .form-control{
  border: 1px solid #fa6c5e !important;
  box-shadow: none !important;
  outline: none !important;
}

.has-error .help-block {
  color: #fa6c5e;
}
