.track-summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 5px;
  outline: none;
  flex-grow: 1;
}

.track-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

.track-details {
  display: flex;
  font-size: 13px;
  button {
    margin-top: 0;
  }

  .completed-alert {
    padding: 6px 12px;
    font-size: 14px;
    font-style: italic;
  }
}

.playlist-icon-wrapper {
  position: absolute;
  right: 10px;
  bottom: 3px;
  @media only screen and (min-width: 768px) {
    span {
      font-size: 20px;
    }
  }
}

