.quiz-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(5, 5, 5, 0.50);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;

  .quiz-modal {
  }

  .quiz-modal-dialog {
    background-color: white;
    font-family: Inter, sans-serif;
    margin-right: 24px;
    margin-left: 24px;
    padding: 24px 27px;
    box-shadow: 0 1px 3px #00000033;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: -50px;
    position: relative;

    h3 {
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;
    }

    .progress-blurb {
      margin-top: 16px;
      font: normal normal normal 16px/21px Inter;
    }
  }

  .close-quiz-confirmation-controls {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 17px;

    .control-item {
      width: 135px;
      .catalyst-button-contained {
      }
    }
  }

  .close-modal-button {
    svg {
      color: pink;
    }
    position: absolute;
    top: -35px;
    right: -10px;
  }
}
