@import "../Rewards";

.my-rewards-body {
  left: -15px;
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;

  @media only screen and (max-width: $tabletBreakpoint) {
    .reward-card {
      padding: 24px 30px;
      border-bottom: 1px solid #E2E2E2;

      .product-details {
        margin-bottom: 24px;
      }

      .po-qualifying-message {
        display: none;
      }

    }
  }

  @media only screen and (min-width: $tabletBreakpoint) {
    padding-top: 32px;
    background: transparent linear-gradient(180deg, #F5F4F4 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;

    .reward-card {
      box-shadow: 0 1px 3px #00000029;
      border-radius: 4px;
      margin-bottom: 20px;
      max-width: $widget-items-max-width;
      padding: 32px;
      background-color: #ffffff;


      .product-details {
        margin-bottom: 16px;
      }

      .details-btn-wrapper {

        .catalyst-button-outlined {
          display: none;
        }
      }
    }
  }

  .reward-card {
    width: 100%;

    .catalyst-button-outlined {
      border: 1px solid #07070780;
      border-radius: 1px;
    }

    @media only screen and (max-width: $tabletBreakpoint) {
      display: flex;
      justify-content: center;
    }

    .reward-card-inner {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row;
      @media only screen and (max-width: $tabletBreakpoint) {
        max-width: $widget-items-max-width-tablet;
      }
    }

    .reward-controls {
      width: 100%;
      flex-grow: 0;
      flex-shrink: 0;
      @media only screen and (min-width: $tabletBreakpoint) {
        width: 40%;
        padding-left: 37px;
        max-width: 278px;
      }
      @media only screen and (max-width: $tabletBreakpoint) {
        .issue-btn-wrapper {
          .catalyst-button-outlined {
            border: none;
            text-align: left;
            font: normal normal 600 12px/16px Inter;
            letter-spacing: 0.18px;
            color: $zt-black;
            height: 12px;
            margin-top: 20px;
          }
        }
      }
    }

    .product-overview {
      max-width: 60%;

      .product-details {
        display: flex;

        .product-details-txt {
          margin-left: 16px;

          p {
            margin-bottom: 4px;
          }

          .product-name {
            font: normal normal 600 14px/17px Inter;
            letter-spacing: 0;
            color: #000000DE;
          }

          .pd-brand-name {
            font: normal normal normal 12px/17px Inter;
            letter-spacing: 0;
            color: #000000DE;
          }

          .earned-at {
            font: normal normal normal 12px/17px Inter;
            letter-spacing: 0;
            color: #000000DE;
          }
        }

        img {
          width: 64px;
        }
      }
    }
  }

  .pagination-controls {
    font: normal normal normal 14px/17px Inter;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: $tabletBreakpoint) {
      margin-top: 24px;
    }
  }
}

.po-qualifying-message {
  font: normal normal normal 14px/17px Inter;
  letter-spacing: 0;
  color: #000000DE;
}

.details-modal {
  position: relative;

  .close-details-mdl-btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  padding: 32px;
  background-color: white;
  max-width: 329px;
  box-shadow: 0px 3px 8px #00000040;
  border: 1px solid #00000014;
  border-radius: 4px;

  h3 {
    font: normal normal 600 18px/21px Inter;
    letter-spacing: 0px;
    color: #070707;
    margin-top: 0;
    margin-bottom: 12px;
  }

  p {
    font: normal normal normal 14px/17px Inter;
    letter-spacing: 0;
    color: #000000DE;
    margin-bottom: 0;
  }

  .issue-product-name-label {
    font: normal normal 600 14px/17px Inter;
    letter-spacing: 0;
    color: $zt-black;
    border: 1px solid $grey-border;
    border-radius: 1px;
    padding: 12px 16px;
    margin-top: -4px;
    margin-bottom: 20px;
  }

  .reward-issue-form-wrapper {
    background: $greyish-background 0% 0% no-repeat padding-box;
    width: calc(100% + 64px);
    padding: 16px 32px 32px;
    position: relative;
    left: -32px;

    .lettuce-no {
      font: normal normal normal 14px/17px Inter;
      letter-spacing: 0;
      color: #000000DE;
      margin-bottom: 16px;
    }

    .reward-issue-form {
      label {
        display: block;
        font: normal normal normal 12px/16px Inter;
        letter-spacing: 0.18px;
        color: #000000BF;
        opacity: 1;
      }
      textarea {
        font: normal normal normal 16px/17px Inter;
        padding: 12px 16px;
        margin-top: 4px;
        display: block;
        width: 100%;
        height: 64px;
        background: #0000000D 0% 0% no-repeat padding-box;
        border-radius: 1px;
        border-bottom: 1px solid #0000006B;
        border-left: none;
        border-top: none;
        border-right: none;
        resize: none;
        outline: none;

      }
      button {
        background: #425AD8 0% 0% no-repeat padding-box;
        border-radius: 1px;
        margin-top: 16px;
      }
    }
  }
}

.issue-modal {
  padding-bottom: 0;
}
