@import "common/styles";

.cpoi-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: white;
  transition: opacity 250ms;
  flex-direction: column;
  padding-bottom: 1.5rem;
  margin-top: 1rem;
}

.cpoi-form {
  flex-grow: 1;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  h3 {
    overflow-wrap: break-word;
    font-size: 18px;
    line-height: 21px;
  }
}

.cpoi-form-wrapper {
  padding-left: 5%;
  padding-right: 5%;
}

.cpoi-form-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;

  .MuiFormControl-root {
    flex-grow: 1;
  }
}

.cpoi-footer {
  flex-grow: 0;
  margin-top: 1rem;
}

.cpoi {
  .image-button-card-img-wrapper {
    outline: none;
  }
}

.cpoi-selected {
  .card-course-card {
    padding-top: 2.5rem;
    padding-bottom: 1rem;
    transition: padding 200ms;
  }
}

.cpoi-selected.cpoi-custom-form {
  .cpoi-body {
    position: relative;
  }

  .Img {
    display: none;
  }
}

.thanks-message {
  margin-top: 1rem;
}

.advanced-cpoi-form-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.ff-wrapper {
  width: 100%;

  .form-error {
    margin-left: 2rem;
  }

  .MuiFormControl-root {
    width: calc(100% - 4rem);
    margin-left: 2rem;
  }
}

.catalyst-form-error {
  label {
    color: #C30035;
  }

  .form-error {
    color: #C30035;
    font-size: 12px;
    line-height: 16px;
    top: -1.5px;
    position: relative;
    font-weight: 300;
  }
}

.file-upload-field {
  margin-bottom: 14.5px;

  &.fuf-has-error {
    .file-upload-label {
      color: #C30035;
    }

    .file-upload-pct-complete {
      background-color: #C30035;
    }
  }

  .file-input-actual {
    visibility: hidden;
    position: absolute;
  }

  .file-upload-label {
    font-family: Inter, serif;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 4px;
  }

  .file-input-controls {

    background-color: #0000000D;
    padding: 16px 100px 16px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    gap: 12px;
    @media only screen and (max-device-width: 736px) {
      padding-right: 16px;
    }

    .add-file-button {
      background-color: #425AD8;
      color: white;
      padding: 8px 16px;
      font: normal normal 600 14px/16px Inter;
      border-radius: 1px;
      border: none;
      margin-right: 12px;
      flex-shrink: 0;
      @media only screen and (max-device-width: 736px) {
         width: 100%;
      }
    }

    .file-name-text {
      font: normal normal normal 14px/16px Inter;
      color: #00000099;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1;
      overflow: hidden;
      display: flex;
      height: 30px;
      align-items: center;
    }

    .file-name-text.file-selected {
      color: #000000;
    }
  }

  .file-upload-pct-complete {
    height: 2px;
    background-color: #425AD8;
    transition: width .1s ease-in-out;
    position: relative;
    top: -2px;
  }

  .catalyst-button-wrapper {
    width: 70px;
    position: absolute;
    right: 25px;
    @media only screen and (max-device-width: 736px) {
      position: unset;
      right: unset;
      margin-right: 10px;
    }
  }

  .catalyst-button-text {
    color: #C30035;
    font: normal normal medium 12px/16px Inter;
    background-color: unset;
  }
}
