@import "../../common/styles/colors";
@import "../../common/styles/loading";

@mixin focused-search-bar {
  border-radius: 0;
  outline: none;
  border-bottom: 1px solid lightgrey;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 3px;
  }
  @media only screen and (min-width: 768px) {
    width: 50%;
    margin-bottom: 6px;
  }
}

.tracks-search-bar-wrapper {
  outline: none;
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding-right: 1.5rem;
  padding-left: 1.5rem;

  input {
    @media only screen and (max-width: 768px) {
      font-size: 16px;
      width: 20px;
      padding-right: 20px;
      margin-bottom: 4px;
    }
    @media only screen and (min-width: 768px) {
      font-size: 19px;
      width: 23px;
      padding-right: 23px;
      margin-bottom: 7px;
    }
    padding-bottom: 2px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    border: none;
  }

  input:focus {
    @include focused-search-bar
  }

  input:not(:placeholder-shown) {
    @include focused-search-bar
  }

  .glyphicon {
    pointer-events: none;
    position: absolute;
    right: 1.5rem;
    top: 5px;
    font-size: 16px;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
    @media only screen and (min-width: 768px) {
      font-size: 19px;
    }
  }
}


.spinner {
  position: relative;
  left: 50%;
  top: 100px;
}

.classes-wrapper {
  padding: 15px 30px 30px;
  margin-bottom: 50px;
}

.classes-wrapper > h3 {
  font-family: 'Brandon-med', sans-serif;
  margin-bottom: 30px;
  text-transform: uppercase;
  background-color: #fff;
  padding: 30px;
  -webkit-box-shadow: 7px 7px 24px -4px rgba(0, 0, 0, 1);
  -moz-box-shadow: 7px 7px 24px -4px rgba(0, 0, 0, 1);
  box-shadow: 7px 7px 24px -4px rgba(0, 0, 0, 1);
}

.classes-list-inner-wrapper {
  display: grid;
  flex-direction: column;
  width: 100%;
  padding-top: .5rem;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  margin: auto;
}

.classes-greeting {
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .classes-list-inner-wrapper {
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr);
  }
  .greeting-header {
    font-size: 20px;
  }
  .classes-greeting {
    white-space: nowrap;
    text-align: left !important;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .classes-list-inner-wrapper {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}

@media only screen and (min-width: 1400px) {
  .classes-list-inner-wrapper {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}


.classes-info {
  background-color: #fff;
  padding: 30px 30px;
  margin-top: 60px;
  -webkit-box-shadow: 7px 7px 24px -4px rgba(0, 0, 0, 1);
  -moz-box-shadow: 7px 7px 24px -4px rgba(0, 0, 0, 1);
  box-shadow: 7px 7px 24px -4px rgba(0, 0, 0, 1);
}


.classes-info > h4 {
  margin: 0;
  margin-bottom: 20px;
  padding: 10px 0;
  font-size: 30px;
  font-family: 'Brandon-med', sans-serif;
  text-transform: uppercase;
}

.classes-info > p {
  font-size: 16px;
}

.classes-materials {
  background-color: #fff;
  padding: 30px 30px;
  -webkit-box-shadow: 7px 7px 24px -4px rgba(0, 0, 0, 1);
  -moz-box-shadow: 7px 7px 24px -4px rgba(0, 0, 0, 1);
  box-shadow: 7px 7px 24px -4px rgba(0, 0, 0, 1);
}

.classes-materials > h4 {
  margin: 0;
  margin-bottom: 20px;
  padding: 10px 0;
  font-size: 30px;
  font-family: 'Brandon-med', sans-serif;
  text-transform: uppercase;
}

.classes-materials > ul > li {
  cursor: pointer;
  padding: 10px 10px;
}

.classes-materials ul li .lesson-title {
  display: block;
  font-size: 25px;
  font-family: 'Brandon-med', sans-serif;
}

.classes-materials .class-items ul li {
  padding: 10px 20px;
}

.classes-materials .class-items ul li a {
  font-size: 20px;
  font-family: 'Brandon', sans-serif;
  color: #000;
}

.classes-materials .class-items ul li a img {
  width: 20px;
  margin-right: 10px;
  margin-top: -5px;
}

/* iphone 5, 5s portrait and landscape */
@media (min-device-width: 320px) and (max-device-width: 568px) {
  .classes-wrapper {
    padding: 0;
  }
}

.employee-employee-classes-title-wrapper {
  display: inline-block;
  position: relative;
  top: 50px;

}

.employee-classes-list {
  margin-bottom: 50px;
}


.employee-classes-list-header {
  background-color: #fff;
  padding: 0 30px 0 0;
  margin: 0 0 0;
  position: relative;
  font-size: 30px;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
}


@media only screen and (max-width: 768px) {
  .employee-classes-list-header {
    margin-top: 10px;
  }
}

.greeting-header {
  margin-bottom: 0;
  margin-top: 15px;
}

.employee-classes-list .employee-classes-list-header > span {
  font-size: 20px;
  margin-top: 10px;
}

.employee-classes-list .employee-classes-list-item {
  background-color: #fff;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  position: relative;
  -webkit-box-shadow: 7px 7px 24px -4px rgba(0, 0, 0, 1);
  -moz-box-shadow: 7px 7px 24px -4px rgba(0, 0, 0, 1);
  box-shadow: 7px 7px 24px -4px rgba(0, 0, 0, 1);
  height: auto;
}


.employee-classes-list .employee-classes-list-item h3.lesson-title {
  font-size: 25px;
  text-transform: uppercase;
  margin: 0;
  position: relative;
  background-color: #fff;
  display: inline-block;
  padding: 21px;
  color: $zt-teal;
  font-family: 'Brandon-med', sans-serif;
}

.employee-classes-list .employee-classes-list-item h4.lesson-completed {
  color: #37b54b;
  display: inline-block;
  font-family: 'Brandon-med', sans-serif;
  font-size: 25px;
  margin: 5px 0;

}

.employee-classes-list .employee-classes-list-item:hover {
}


.employee-classes-list .employee-classes-list-item .employee-classes-list-item-actions {
  padding-left: 0;
}

.employee-classes-list .employee-classes-list-item .employee-classes-list-item-actions h5 {
  font-family: 'Brandon-med', sans-serif;
  font-size: 18px;
}

.employee-classes-list .employee-classes-list-item .employee-classes-list-item-action {
  color: #000;
  padding: 20px 30px;
  font-family: 'Brandon-med', sans-serif;
  font-size: 25px;
  cursor: pointer;
}

.employee-classes-list .employee-classes-list-item .employee-classes-list-item-action.not-available {
  color: #b5b5b5;
  padding: 20px 30px;
  font-family: 'Brandon-med', sans-serif;
  font-size: 25px;
  cursor: default;
}

.employee-classes-list .employee-classes-list-item .employee-classes-list-item-action:hover {
}

.employee-classes-list .employee-classes-list-item .employee-classes-list-item-action img {
  width: 30px;
  margin-top: -5px;
  margin-right: 20px;
}

/* iphone 5, 5s portrait and landscape */
@media (min-device-width: 320px) and (max-device-width: 568px) {
  .classes-wrapper {
    padding: 0;
  }
}


