.brand-dispensary-detail .dispensary-info-panel {
  font-size: 18px;
}

.brand-dispensary-detail {
  font-family: Inter, sans-serif;
  display: flex;
  flex-direction: column;
  height: 100%;
  .MuiButtonBase-root {
    margin-top: 1.5rem;
  }
  .tab-panel {
    margin-left: -1.5rem;
    width: 100vw;
    height: 100%;
  }
}
