.quiz-card {
  border-radius: 4px;
  box-shadow: 0 1px 3px #00000033;
  margin-bottom: 2.0rem;
  background-color: #070707;
  color: white;
  font-family: Inter, sans-serif;

  h3 {
    margin-top: 0;
    font: normal normal 600 24px/26px;
  }

  @media only screen and (max-width: 600px) {
    padding: 20px;
  }

  @media only screen and (min-width: 600px) {
    padding: 32px;
    margin-left: auto;
    margin-right: auto;
    max-width: 55ch
  }

  .min-score-blurb {
    font: normal normal 600 16px/20px;
  }

  &.has-passed {
    .quiz-prompt {
      button {
        background-color: white;
      }
    }
  }

  .quiz-prompt {
    width: 100%;
    background-color: white;
    padding: 24px;
    margin-top: 24px;

    .quiz-prompt-overview {
      display: flex;

      .qp-overview-label {
        color: #000000AB;
        text-transform: uppercase;
        font: normal normal 600 10px/12px Inter;
        letter-spacing: 1.25px;
      }

      .chances-left {
        margin-right: 29px;
      }

      .cl-counter {
        margin-top: 8px;
        color: #070707;
        font: normal normal 600 18px/21px Inter;
      }

      margin-bottom: 24px;

      .score-overview {
        display: flex;
        align-items: flex-end;

        .cl-counter {
          margin-right: 5px;
        }

        .pass-fail-indicator {
          color: black;
          font-size: 12px;
          letter-spacing: 0.18px;
          border-radius: 4px;
          background-color: #FF828BCC;
          display: inline-block;
          padding-right: 4px;
          padding-left: 4px;

          &.pass {
            background-color: #8BFC9D;
          }
        }
      }
    }

    button {
      background-color: #425AD8;
      font: normal normal 600 14px/16px Inter;
      letter-spacing: 0.21px;
    }

  }

  .quiz-questions-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1031;
    background-color: #070707;
    display: flex;
    flex-direction: column;

    .icon-button-wrapper {
      flex-grow: 0;
      width: 100%;
      position: relative;
      height: 48px;

      span {
        color: white;
      }

      button {
        right: 0;
        position: fixed;
        top: 10px;
      }
    }

    .questions-body {
      flex-grow: 1;
      border-radius: 12px 12px 0 0;
      color: black;
      background: #F5F5F5 0 0 no-repeat padding-box;
      padding: 28px 30px;
      max-height: calc(100vh - 40px);
      overflow-y: auto;
      .qb-title {
        font: normal normal 600 14px/17px Inter;
        letter-spacing: 0px;
        color: #425AD8;
        margin-bottom: 16px;
        min-width: 0;
        @media only screen and (min-width: 768px) {
          max-width: 550px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      .quiz-inner-wrapper {
        @media only screen and (min-width: 768px) {
          max-width: 550px;
          margin: auto;
        }

        .quiz-question {
          &.missing-answer-qq {
            border: 1px solid #C30035;
          }

          background: #FFFFFF 0 0 no-repeat padding-box;
          box-shadow: 0 1px 3px #00000033;
          border-radius: 4px;
          padding: 20px 15px;
          margin-bottom: 14px;

          .question-counter {
            color: #425AD8;
            font: normal normal 600 10px/16px Inter;
            background: #425AD814 0 0 no-repeat padding-box;
            border-radius: 1px;
            display: inline-block;
            padding: 2px 4px;
            letter-spacing: 1.25px;
            margin-bottom: 10px;
          }

          .qq-select-all-message {
            text-transform: uppercase;
            font: normal normal 600 10px/16px Inter;
            letter-spacing: 1.25px;
            color: #000000AB;
            margin-bottom: 8px;
          }

          .question-title {
            font: normal normal 600 16px/20px Inter;
            letter-spacing: 0;
            color: #070707;
            margin-bottom: 12px;
          }
        }

        .missing-answers-message {
          width: 100%;
          text-align: center;
          font: normal normal 400 12px/16px Inter;
          letter-spacing: 0.18px;
          color: #C30035;
          margin-bottom: 12px;

        }
        .quiz-submit-controls {
          margin-top: 34px;
          margin-bottom: 34px;
        }
      }
    }
  }

  .quiz-overview {
    .overview-body {
      border-radius: 4px;
      background-color: white;
      padding-bottom: 24px;
      @media only screen and (min-width: 768px) {
        max-width: 550px;
        margin: auto;
      }

      .overview-summary {
        padding-left: 20px;
        padding-right: 20px;
      }

      .missed-question-overview {
        margin-bottom: 32px;
        margin-top: 24px;

        .mqo-header {
          font: normal normal normal 22px/25px Inter;
          letter-spacing: 0;
          color: #070707;
          margin-bottom: 16px;
        }

        .mq-position {
          font: normal normal 600 10px/16px Inter;
          letter-spacing: 1.25px;
          color: #000000AB;
          text-transform: uppercase;
          margin-bottom: 12px;
        }

        .mq-title {
          font: normal normal 600 16px/20px Inter;
          letter-spacing: 0;
          color: #000000;
          margin-bottom: 16px;
        }
      }

      .back-to-course-wrapper {
        &.no-incorrect-answers {
          margin-top: 40px;
        }
      }

    }

    .overview-header {
      display: flex;
      color: white;
      background: #070707 0 0 no-repeat padding-box;
      padding: 20px 20px 20px 20px;
      justify-content: space-between;
      border-radius: 4px 4px 0px 0px;

      .overview-info {
        .oi-message {
          font: normal normal 600 32px/34px Inter;
          letter-spacing: -0.48px;
        }

        .oi-details {
          font: normal normal normal 22px/25px Inter;
          margin-top: 4px;
        }
      }

      .overview-score {

        &.os-passed {
          .overview-score-box {
            background-color: #8BFC9D;
          }
        }

        .overview-score-box {
          top: 0;
          width: 80px;
          height: 60px;
          margin-left: 12px;
          background-color: #FF828B;
          color: black;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          border-radius: 4px;

          .os-pass-fail {
            font: normal normal 600 10px/16px Inter;
            letter-spacing: 1.25px;
            text-transform: uppercase;
          }

          .os-score {
            font: normal normal 600 24px/38px Inter;
            letter-spacing: 0;
            color: #070707;
          }
        }

      }
    }
  }
}
