@font-face {
    font-family: "FiraSans-Regular";
    src: url(./assets/fonts/FiraSans-Regular.ttf) ;
}

a {
    cursor: pointer;
}

body {
    background-color: white;
    background-size: cover;
    overflow-x: hidden;
    height: 100%;
    min-height: 100%;
}

.max-height {
    height: 100%;
    min-height: 100%;
}

.main {
    position: relative;
    height: 100%;
    padding-top: 70px;
    min-height: 600px;
}

@media (max-width: 768px) {
    .main {
        padding-top: 70px;
    }
}
@media (min-width: 768px) {
    .main {
        padding-top: 85px;
    }
}

.no-scroll {
    overflow: hidden;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .form-control {
        font-size: 16px;
    }
}

@media (min-width: 768px) {
    .form-control {
        font-size: 14px;
    }
}

