.reset-password-button-wrapper {
  margin-bottom: 83px;
}

.forgot-password-error {
  color: #C30035;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
}
@media only screen and (max-device-width: 736px) {
  .forgot-password-error {
    width: 266.3px;
  }
}
@media only screen and (min-device-width: 736px) {
  .forgot-password-error {
    width: 327.5px;
  }
}
