.welcomes-message {
    float: left;
    color: white;
    position: relative;
    top: 20px;
    left: 15px;
}

.admin-navigation {
    /*max-width: 100vw;*/
}
