@import "src/common/styles/colors";
@mixin bcli-button {
  border-radius: 1px;
  opacity: 1;
  border: 1px solid #000000;
  padding: 8px 12px 9px 30px;
  color: #000000DE;
  font: normal normal 600 14px/21px Inter;
  letter-spacing: 0;
  line-height: 17px;
  position: relative;


  img {
    position: absolute;
    height: 20px;
    left: 6px;
    top: 6px;
  }
  background-color: white;
}

@mixin vcb-button {
  padding-left: 12px;
  font: normal normal 600 14px/16px Inter;
  letter-spacing: 0.21px;
  color: #FFFFFF;
  height: 33px;
  padding-left: 30px;
  padding-right: 6px;

  position: relative;
  img {
    position: absolute;
  }

  svg {
    position: absolute;
  }


}
$desktopMaxBtnWidth: 401px;

@mixin purple-btn {
  .catalyst-button-wrapper {
    max-width: $desktopMaxBtnWidth;

    .catalyst-button-contained {
      background-color: $current-draft-purple;
    }
  }
}
