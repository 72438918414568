$zt-orange: #FB5F00;

$zt-green: #009742;

$zt-maroon: #A1304A;

$zt-black: #070707;
$zt-offlback: #383838;

$zt-black-lighter: #1e1e1e;
$zt-add-blue: #425AD8;

$zt-teal: #00A19C;
$zt-teal-80: #00A19C80;
$zt-teal-60: #00A19C60;
$zt-teal-next: #3CCFBE;
$zt-star-yellow: #FFF04B;
$greyish-background: #F5F4F4;
$grey-border: #E2E2E2;
$black-font: #070707;
$version-control-bar-height: 49px;
$header-height: 85px;
$current-draft-purple: #425ad8;
$published-version-green: #039557;
$greyish-background: #F5F4F4;
