@import "../../../../../../../common/styles/colors";

.survey-value-item-selector {
  span {
    outline: none;
  }
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.survey-value-items {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
