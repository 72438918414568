@import "src/features/CardCourses/CardCourse/cards/CatalystFormCard/common/styles";

.catalyst-text-input {
  @include catalyst-input;
  display: block;
  width: 100%;
  height: 44px;
  font-size: 16px;
  margin-top: 4px;
  line-height: 20px;
  font-weight: 400;
}


.catalyst-input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;

  label {
    @include catalyst-label;
  }
}

.catalyst-form-error {
  label {
    color: #C30035;
  }

  .catalyst-input-helper-text {
    color: #C30035;
    font-size: 12px;
    line-height: 16px;
    top: -1.5px;
    position: relative;
    font-weight: 300;
  }

  .catalyst-text-input {

    border-bottom: 2px solid #C30035;

    &:focus {
      border-bottom: 2px solid #C30035;
    }
  }
}
