.form-wrapper {
    padding: 30px;
    margin-top: 50px;
    background: url('../../assets/images/black-transparent-bg.png') repeat;
}

.form-message {
    font-family: 'Brandon-med', sans-serif;
    text-transform: uppercase;
    margin-bottom: 45px;
    color: #fff;
    text-align: center;
}

.form-wrapper h4 {
    font-family: 'Brandon', sans-serif;
    color: #fff;
    text-align: center;
}

.form-wrapper .form-link-wrapper {
    margin-top: 30px;
}

.form-wrapper .form-link {
    font-family: 'Brandon', sans-serif;
    font-size: 18px;
    color: #cdcdcd;
}

.form-wrapper .form-link:hover {
    color: #3d91cd;
    text-decoration: none;
}

/* iphone 5, 5s portrait and landscape */
@media (min-device-width : 320px)
and (max-device-width : 568px) {
    .form-wrapper {
        margin-top: -3rem;
        h4 {
            margin-bottom: 1.5rem;
            margin-top: 1rem;
        }
        h2 {
            margin-bottom: 2rem;
        }
    }
}

.existing-account-message {
    color: white;
    margin-bottom: 1.5rem;
    font-style: italic;
    text-align: center;
}
