@import "../../common/styles/fonts";
@import "../../common/styles/forms";

$maxTabletSize: 1366px;

.create-password-background {
  @include login-background-mask;
}

.create-password-logo {

  img {
    height: 75px;
    margin-bottom: 16px;
  }

  @media (min-width: $maxTabletSize) {
    position: relative;
    left: -12px;
    margin-bottom: 16px;
    img {
      height: 85px;
    }
  }
}

.cp-welcome-message {
  @media (min-width: $maxTabletSize) {
    margin-bottom: 32px;
  }
}

.create-password-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 78px;
  position: relative;

  margin-bottom: auto;

  @media (max-width: $maxTabletSize) {
    grid-template-columns: 1fr;
    padding-bottom: 95px;
    margin-top: 30px;
    margin-bottom: 32px;
  }
  @media (min-width: $maxTabletSize) {
    margin-top: auto;
    top: -40px;
  }

  .cp-welcome-section {
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $maxTabletSize) {
      align-items: center;
    }

    h2 {
      font: normal normal 600 50px/52px Inter;
      letter-spacing: -0.75px;
      color: #FFFFFF;
      margin-top: unset;
      margin-bottom: unset;

      @media (max-width: $maxTabletSize) {
        text-align: center;
        font: normal normal 600 28px/32px Inter;
        letter-spacing: -0.24px;
        color: #FFFFFF;
      }
    }
  }

  .cp-form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 48px;
    padding-right: 48px;
    .reset-password-form-inner {
      margin-top: 24px;
    }

    .cp-message {
      text-align: center;
      font: normal normal 400 16px/20px Inter;
      letter-spacing: 0;
      color: #070707;
    }

    @include login-form-wrapper;
    flex-shrink: 0;
    flex-grow: 0;
    width: 415px;

    @media (max-width: $maxTabletSize) {
      margin-top: 24px;
      padding: 32px 32px;
    }

    @media (max-width: $maxMobileSize) {
      width: 315px;
      padding: 32px 32px;
    }
  }

  .cp-shapes {
    @media (max-width: $maxTabletSize) {
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-basis: 100%;
      flex-wrap: wrap;
    }
  }

  .cp-hb-announcement {
    color: #fff;
    font-size: 14px;
    padding-top: 32px;
  strong {
    color: #FF35D2;
    font-weight: 800;
  }
  @media (max-width: $maxTabletSize) {
    padding-top: 16px;
    text-align: center;
  }
  }

  .cp-already-account {
    font: normal normal normal 14px/20px Inter;
    letter-spacing: 0.07px;
    color: #000000DE;
    margin-top: 24px;
  }
}
