.track-details-view {
  display: flex;
  flex-direction: column;
  margin: auto;
  font-size: 16px;
  height: 100%;
  align-items: center;
  @media only screen and (max-device-width: 736px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.details-column {
  display: flex;
  flex-direction: column;
  max-width: 650px;
  width: 100%;
  flex: 1;
  align-items: flex-start;
}
