@import "../common";

$track-list-padding: 1.5rem;

.dispensary-track-list {
  @include track-panel;
  margin-right: 1.5rem;
  background-color: white;
  overflow-y: hidden;
  min-height: 0;
  padding-left: $track-list-padding;
  padding-right: $track-list-padding;
}

.dtl-btn-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: -3rem;
  .glyphicon {
    color: $styled-teal;
    font-size: 40px;
  }
}

.dtd-course-track-items {
  margin-top: 1rem;
}

.at-wizard-modal-content {
  outline: none;
}

.dtl-inner {
  overflow-y: auto;
  max-height: calc(100% - 40px);
  padding-left: 3rem;
  padding-right: 3rem;
}

.remove-btn {
  @include zt-button;
  margin-right: 1rem;
  color: $styled-teal;
  background-color: white;
  border: solid 3px $styled-teal;
}
