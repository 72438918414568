.save-brand-employee-form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
}

.save-brand-employee-form-items {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  flex: 1;
  max-width: 700px;
  padding: 1.0rem 3.0rem 4.0rem;
  margin-top: 2.0rem;

  h3 {
    margin-top: 10px;
  }

  .invalid-feedback {
    color: red;
    margin-top: 1rem;
    margin-left: 1rem;
  }

  form {
  }

  .text-form-group {
    margin-bottom: 1.0rem;

    label {
      width: 100%;
    }

    input {
      font-weight: normal;
      width: 100%;
    }

    input[type=text], input[type=email] {
      position: relative;
      top: .5rem;
      padding: 5px;
    }
  }
}

.save-brand-employee-form-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .header-control {
    cursor: pointer;
    font-size: 20px;
    margin-right: 15px;
  }
}

.brand-employee-form-buttons {
  button {
    margin-right: 1.0rem;
  }
}
