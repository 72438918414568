@import '../common';

.dispensary-track-detail-header {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-bottom: 3px solid $styled-grey;
  margin-bottom: 4rem;
}

.dispensary-track-details {
  @include track-panel;
  overflow-y: auto;
  margin-left: 1.5rem;
  background-color: white;
  padding-left: 2rem;
  padding-right: 2rem;
  .MuiCheckbox-root {
  }

  font-family: "Open Sans", sans-serif;
}

.dtd-instructions {
  font-style: italic;
  color: $dark-grey-text;
  max-width: 500px;
  margin-bottom: 2rem;
}

.drtd-details {
  margin-bottom: 2rem;
}


.drtd-wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
  .dtd-instructions {
  }
}

.dtd-spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
