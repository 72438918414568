.modal-overlay-av {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 10000;
  font-family: Roboto, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.av-modal-content {
  outline: none;
}

.av-spinner {
}

.av-modal-inner {
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5vh 5vw;
}

.av-logo-wrapper {
  height: 102px;
  width: 240px;
  display: flex;
  justify-content: center;
  img {
    max-height: 100%;
  }
}

.av-message {
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .av-modal-inner {
  }
  .av-modal-content {
    top: -10%;
  }
  .modal-inner {
    top: -10px;
    position: relative;
  }
}

@media only screen and (max-width: 768px) {
  .av-modal-inner {
  }
}

.modal-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.verification-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  .btn {
    margin-right: 10px;
    margin-left: 10px;
  }
}
