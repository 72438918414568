.lfc-map {
  height: calc(100vh - 200px);
}

.lfc-locating {
  position: relative;
  left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}


.marker-info-window {
  margin: 1rem;
}
