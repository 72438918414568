.brand-dispensary-progress {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: auto;
  padding-bottom: 24px;

  .bdo-overviews {
    padding-left: 24px;
    padding-right: 150px;
    margin-top: 24px;

    .course-overview-header {
      margin-bottom: 5px;
      border-bottom: 1px solid #dadce0;
    }

    .bdo-grid-header {
      font-weight: bold;
      display: grid;
      grid-template-columns:  30px 1fr 200px;
      padding-top: 10px;
      padding-bottom: 5px;
      padding-left: 10px;
    }

  }

  .brand-dispensary-overview {
    .feedback-score {
      margin-bottom: 30px;
      text-transform: uppercase;
    }
  }
}
