.receive-updates-from-brand-wrapper {
  margin-top: 12px;
  width: 300px;
  align-self: center;
  border-radius: 4px;
  background-color: #425AD814;
  padding: 8px;

  label {
    display: flex;
  }

  .receive-updates-label {
    font: normal normal normal 14px/17px Inter !important;
    letter-spacing: 0;
    color: #000000;
    margin-left: 7px;
  }

  .opt-in-checkbox {
    -webkit-appearance: none;
    appearance: none;
    font: inherit;
    color: currentColor;
    width: 20px;
    height: 20px;
    margin-top: 0 !important;

    &:focus {
      outline: none !important;
    }

    background-color: black;
    mask: url('../../../../assets/images/checkbox-unselected.svg') 0 center no-repeat;
    outline: none;

    &:checked {
      mask: url('../../../../assets/images/checkbox-selected.svg') 0 center no-repeat;
      background-color: black;
    }
  }
}
