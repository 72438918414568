@import "../../../../common/styles/modals";
.employee-editor {
  display: flex;
  height: 100%;
  padding: 2.0rem;
  justify-content: center;
}

.be-delete-confirmation-modal-overlay {
  @include common-modal-overlay;
}

.be-modal-content {
  @include common-modal-content;
}
@media only screen and (min-width: 800px) {
  .be-modal-content {
    @include common-modal-content-mobile-adjustment;
  }
}

.be-delete-modal-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-confirmation-buttons {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.0rem;
  .btn-danger {
    margin-left: 2.0rem;
  }
}
