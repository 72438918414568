.ff-question-card-answer-button {
  margin-top: 1.5rem;
  margin-bottom: .5rem;
}

.ff-answer-button-wrapper {
  display: block;
  justify-content: center;
}

.ff-text-input-wrapper {
  display: flex;
}

.ff-text-area {
  width: 100%;
  padding: 5px;
  height: 100px;
  resize: none;
}

