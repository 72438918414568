@import "../../common/styles/forms";
@import "../../common/styles/fonts";

.reset-password-background {
  @include login-background-mask;
}

.reset-password-zt-logo {
  position: absolute;
  top: 16px;

  img {
    height: 97px;
  }
}

.reset-shapes {
  position: absolute;
  bottom: -45px;
}

.reset-password-form-wrapper {
  @include login-form-wrapper;
  margin-bottom: 100px;
  margin-top: 133px;
  .welcome-message {
    h5 {
      text-align: center;
    }
  }
}

@media only screen and (max-device-width: 736px) {
  .reset-password-form-wrapper {
    width: calc(266.3px + 48px);
  }
}

@media only screen and (min-device-width: 736px) {
  .reset-password-form-wrapper {
    width: calc(327.5px + 48px);
  }
}
