@import "../../common";

.dispensary-track-item {
  background-color: white;
  outline: none;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border-bottom: 3px solid $styled-grey;
}

.dti-control {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  .btn-link {
    color: $dark-grey-text;
    font-size: 16px;
    text-decoration: underline;
    font-style: italic;
    outline: none;
  }
}

.dti-selected {
  background-color: $styled-grey;
  border-bottom: 3px solid white;
  border-radius: 2rem;
  position: relative;
  overflow: visible;
}

.dti-selected:after{
  content: '';
  display: block;
  position: absolute;
  top: calc(50% - 12px);
  left: 100%;
  width: 0;
  height: 0;
  border-color: transparent transparent transparent $styled-grey;
  border-style: solid;
  border-width: 12px;
}

.dti-before-selected {
  border-bottom: 3px solid white;
}

.dti-img {
  width: $img-width;
  margin: 2rem;

  .Img {
    border-radius: 50%;
  }
}

.dti-details {
  margin-left: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.dti-title {
  font-weight: bold;
  font-size: 22px;
  flex-grow: 0;
}

.dti-item-list {
  flex-grow: 1;

  label {
    margin-bottom: 0;
  }

  .MuiCheckbox-root {
  }
}
