/* Move down content because we have a fixed navbar that is 50px tall */

@import "../../common/styles/colors";

@font-face {
  font-family: 'Ostrich';
  src: url('../../assets/fonts/ostrich-regular.ttf');
}

@font-face {
  font-family: 'Ostrich-bold';
  src: url('../../assets/fonts/OstrichSans-Bold.otf');
}

@font-face {
  font-family: 'Ostrich-black';
  src: url('../../assets/fonts/OstrichSans-Black.otf');
}


@font-face {
  font-family: 'Brandon';
  src: url('../../assets/fonts/Brandon_light.otf');
}

@font-face{
  font-family: 'Brandon-med';
  src: url('../../assets/fonts/Brandon_med.otf');
}

@font-face{
  font-family: 'Brandon-bld';
  src: url('../../assets/fonts/Brandon_bld.otf');
}

@font-face{
  font-family: 'Brandon-black';
  src: url('../../assets/fonts/Brandon_blk.otf');
}


/*
 * Top navigation
 * Hide default border to remove 1px line.
 */
.navbar-fixed-top {
  border: 0;
}

/*
 * Main content
 */
.btn-success {
  background: #00A19C !important;
  border-color: #00A19C !important;
}


.main-container {
}

@media (min-width: 768px) {

}

.app, .public-app {
  height: 100%;
  min-height:100%;
  position: relative;
  overflow-x: hidden;
}

.dispensary-admin-app {
  height: 100%;
  background-color: white;
  font-family: 'Roboto', sans-serif;
}

.dispensary-admin-content-row {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.dispensary-admin-menu {
  min-width: 180px;
  color: rgb(51, 51, 51);
  background-color: #f1f1f2;
  border-right: 1px solid rgb(231, 231, 233);
}

.dispensary-admin-content {
  flex: 1;
}

.online-classes-title, .class-price {
  font-family: 'Brandon-black', sans-serif;
  font-size:35px;
  color: #666;
  text-transform: uppercase;
}

.class-price {
  color: #d0d0d0;
  font-family: 'Brandon-med', sans-serif;
  font-size: 55px;
  margin-bottom: 130px;
  text-align: center;
}

.class-name {
  font-family: 'Brandon', sans-serif;
  font-size:45px;
  color: white;
  text-transform: uppercase;
  font-weight: normal;
  margin-top: 50px;
  border-bottom: 2px solid #fff;
  text-align: center;
  padding-bottom: 30px;
}

.class-name > span {
  font-family: 'Brandon-med', sans-serif;
}

.buy-class {
  text-transform: uppercase;
  background-color: $zt-teal;
  opacity: 0.9;
  color: #fff;
  font-size: 35px;
  font-weight:bold;
  font-family: 'Brandon-med', sans-serif;
  text-align: center;
  width: 100%;
  display: block;
  padding-top: 15px;
  padding-bottom: 10px;
  letter-spacing: 1px;
}

.buy-class:hover {
  text-decoration: none;
  color: #fff;
  opacity: 1;
  cursor: pointer;

}


.content-section {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #f2f2f2;
}

.content-section:last-child {
  border-bottom: 0;
}

.content-section-title {
  font-size: 35px;
  font-family: 'Brandon-black', sans-serif;
  color: #565656;
  margin-bottom: 20px;
}

.content-section-content {
  font-size: 17px;
  font-family: 'FiraSans-Regular', sans-serif;
  font-weight: normal;
  color: #585858;
}

.feature-block, .works-block {
  display: inline-block;
  width: 45%;
  max-width: 45%;
  margin-right: 30px;
  margin-bottom: 30px;
}

.feature-block .feature-check {
  color: #07c527;
  margin-right: 10px;
}

.works-text{
  margin-bottom: 30px;
}

.works-block .works-check {
  color: #ed1c24;
}

.lessons-overview-wrapper {
  background-color: #fff;
  margin-bottom: 50px;
  -webkit-box-shadow: 7px 7px 24px -4px rgba(0,0,0,1);
  -moz-box-shadow: 7px 7px 24px -4px rgba(0,0,0,1);
  box-shadow: 7px 7px 24px -4px rgba(0,0,0,1);
}

.lessons-overview-title {
  font-family: 'Brandon-med', sans-serif;
  color: #424242;
  font-size: 25px;
  margin-bottom: 30px;
  text-transform: uppercase;
}



.lessons-timeline .glyphicon {
  font-size: 16px;
  margin-top: -1px;
  margin-left: 1px;
}

.welcome-to-class {
  margin-top:50px;
  margin-bottom: 50px;
  margin-left:0;
  font-family: 'Ostrich-black', sans-serif;
  font-size: 35px;
}

.classes-outline {
  margin-top: 50px;
}

.classes-outline h3{
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-size: 18px;
  margin-bottom: 10px;
}

.classes-outline-left {
  text-align: left;
  color: #525252;
}
.classes-outline-right {
  text-align: right;
  color: #525252;
}

.classes-outline-stats {
  text-transform: uppercase;
  color: #525252;
}

.classes-outline-stats-center {
  text-align: center;
  color: #cdcdcd;
}

.classes-outline-stats-right {
  text-align: right;
}

.class-resources{
  margin-top: 50px;
}
.class-resources h3{
  font-size: 35px;
  font-family: 'Ostrich-black', sans-serif;
  margin-bottom: 50px;

}



.video-class-overview, .show-class-overview, .show-previous-video{
  position: absolute;
  right: 0;
  top: 0;
  width: 550px;
  height: 100%;
  opacity: 0.9;
  background-color: #313131;
  z-index: 1;
  visibility: hidden;
  transition: visibility 0s, opacity 1s linear;
  padding-bottom: 30px;
  overflow-y: auto;;
}

.show-class-overview {
  position: absolute;
  right: 0;
  top: 50%;
  width: 50px;
  height: 50px;
  opacity: 0.7;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  color: #fff;
  z-index: 1;
  visibility: hidden;
  padding-top: 15px;
  padding-left: 5px;
  font-size: 18px;
  text-align: center;
  overflow: hidden;
}
.show-class-overview-visible {
  visibility: visible;
}

.video-class-overview-visible{
  visibility: visible;
  transition: visibility 0s, opacity 1s linear;
}

.show-previous-video {
  border-radius: 0;
  left: 0px;
  top: 50%;
  color: white;
  background-color: #313131;
  width: 150px;
  height: 15px;
  padding-left: 10px;
  padding-top: 10px;
}
.show-previous-video-visible{
  display: block;
}

.video-class-overview {
  padding-left:20px;
  padding-right:20px;
  overflow-x: hidden;
  overflow-y: auto;
}

.video-class-overview .lessons-overview-item-content,
.video-class-overview .lessons-overview-item-title{
  color: #fff;
  margin: 0;
}

.video-class-overview .lessons-overview-item-title strong {
  color: #fff;
}


.video-class-overview .lessons-overview-item.open{
  background-color: black;
}

.video-class-overview .lessons-overview-item .video-overview-play {
  display: block;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: black;
  color: #fff;
  border: 1px solid #fff;
  text-align: center;
}

.video-class-overview .lessons-overview-title {
  color: #fff;
  margin-left:15px;
}

.video-class-overview .lessons-overview-item .lessons-overview-item-play-video,
.video-class-overview .lessons-overview-item .lessons-overview-item-play-keytakeaway {
  color: #fff;
}

.video-class-overview .certificate-container{
  display: none;
}


.class-video-container {
  padding-top: 30px;
  padding-bottom: 30px;
  /*background: url('../../assets/images/black-transparent-bg.png') repeat;*/
  background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.9) 77%, rgba(0,0,0,0.8) 99%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%,rgba(0,0,0,0.9) 77%,rgba(0,0,0,0.8) 99%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0.9) 77%,rgba(0,0,0,0.8) 99%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#cc000000',GradientType=0 ); /* IE6-9 */

}


.wyg-overview-wrapper {
  margin-top:50px;
}


.side-lines-border {
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.side-lines-border::before {
  content: '';
  display: block;
  position: absolute;
  border-left: 400px solid #424242;
  height: 3px;
  left: 0;
  top: 50%;
}

.side-lines-border::after {
  content: '';
  display: block;
  position: absolute;
  border-right: 400px solid #424242;
  height: 3px;
  right: 0;
  top: 50%;
}

@media (min-device-width : 320px)
and (max-device-width : 568px) {
  .side-lines-border::before {
    border-left-width: 20px;
  }
  .side-lines-border::after{
    border-right-width: 20px;
  }

  .side-lines-border.side-lines-border-white::before {
    border-left-width: 20px;
  }
  .side-lines-border.side-lines-border-white::after {
    border-right-width: 20px;
  }
}

/* iphone 6 portrain & landscape */
@media (min-device-width : 375px)
and (max-device-width : 667px) {
  .side-lines-border::before {
    border-left-width: 50px;
  }
  .side-lines-border::after{
    border-right-width: 50px;
  }
}

/* Portrait and Landscape */
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (-webkit-min-device-pixel-ratio: 2) {
  .side-lines-border::before {
    border-left-width: 130px;
  }
  .side-lines-border::after {
    border-right-width: 130px;
  }
}



/* iphone 6plus portrain & landscape */
@media (min-device-width : 414px)
and (max-device-width : 736px) {
}

.side-lines-border-white {
  color: #fff;
}

.side-lines-border-white::before {
  border-left-color: #fff;
}
.side-lines-border-white::after{
  border-right-color: #fff;
}

.side-lines-border-black {
  color: #000;
}


.side-lines-border-black::before {
  border-left-color: #000;
}
.side-lines-border-black::after{
  border-right-color: #000;
}


.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-right: 0;
}

.padding-5-horizontal{
  padding-left: 5px;
  padding-right: 5px;
}

@media (min-width: 1200px)
and (max-width: 1600px) {
  .class-name {
    margin-top: 20px;
  }

  .class-price {
    margin-bottom: 20px;
    font-size: 45px;
  }

  .buy-class {
    font-size:25px;
  }

  .side-lines-border::before {
    border-left-width: 270px;
  }

  .side-lines-border::after {
    border-right-width: 270px;
  }

  .what-you-get-container .wyg-box h2{
    font-size:30px;
  }

  .knowledge-wrapper{
    margin-top: -210px;
  }

}

@media (min-width: 1024px)
and (max-width: 1200px) {
    .side-lines-border::before {
      border-left-width: 200px;
    }

    .side-lines-border::after {
      border-right-width: 200px;
    }


}



/* iphone 5, 5s portrait and landscape */
@media (min-device-width : 320px)
and (max-device-width : 568px) {
  .app {
  }


  .wyg-overview-wrapper {
    margin-top:50px;
    margin-bottom:50px;
  }
}

html {
}

* {
}

@media print {
  .card-course-card {
    width: 2.833in;
  }
}

.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #00000080;
  z-index: 10000;
  font-family: Roboto, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
