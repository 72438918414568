.takeover-loading {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    min-height:100%;
    z-index:100;
    padding-top: 80px;
    background-color: white;
}
