.profile-wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: #fff;
    padding: 30px;
}


.profile-wrapper h3{
    font-family: 'Brandon-med', sans-serif;
    margin-bottom: 30px;
    text-transform: uppercase;
}