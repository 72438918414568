@import "../../../../../../../common/styles/colors";

.survey-comments-wrapper {
  display: flex;
  textarea {
    width: 100%;
    height: 73.5px;
    border-radius: 1px;
    border: 1px solid #FFFFFF80;
    background-color: $zt-black;
    margin-bottom: 16px;
    padding: 12px 15.5px;
    font: normal normal normal 16px/21px Inter;
    letter-spacing: 0px;
    color: #FFFFFF;
    &:focus {
      outline: none;
    }
  }
}
