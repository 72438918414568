.classes-list-item {
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px; /* 5px rounded corners */
  width: 100%;
}

.list-item-media {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 5px 5px 0 0;
}

.cli-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cli-tappable-primary {
  flex-grow: 1;
}
