@import "../../../../common/styles/modals";

.confirmation-modal-overlay {
  @include common-modal-overlay;
}

.modal-content {
  @include common-modal-content;
}

@media only screen and (min-width: 800px) {
  .modal-content {
    @include common-modal-content-mobile-adjustment;
  }
}

.modal-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmation-buttons {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-top: 1rem;
  .btn-confirm {
    margin-left: 2.0rem;
  }
}
