.zt-button-link {
  background-color: #303030;
  color: white;
  border-radius: 1px;
  padding: 12px 60px;
  display: inline-block;
  white-space: nowrap;

  &:hover {
    color: white;
    text-decoration: none;
  }

  font: normal normal 600 14px/16px Inter;

  &:focus {
    color: white;
    text-decoration: none;
  }
}

