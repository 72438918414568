@import "./colors";

@mixin common-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(5, 5, 5, 0.75);
  z-index: 10000;
  font-family: Roboto, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin common-modal-content {
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 5vh 5vw;
}

@mixin common-modal-content-mobile-adjustment {
  .revert-draft-modal-content {
    top: -10%;
    position: relative;
  }
}

@mixin common-modal-content-v2 {
  background-color: white;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;

  .are-you-sure-msg {
    font: normal normal 600 18px/21px Inter;
    letter-spacing: 0;
    color: $zt-black;
    margin-bottom: 16px;
  }
}

@mixin common-modal-controls {

  background: $greyish-background 0% 0% no-repeat padding-box;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 73px;
  display: flex;
  align-items: center;
  width: 100%;
}

@mixin common-modal-cancel-btn  {
  color: $zt-black;
  border: none;
  background-color: $greyish-background;
  width: 80px;
}
