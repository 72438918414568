.dta-loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .spinner {
    top: unset;
    left: unset;
  }
}

.dtar-wrapper {
  width: 100vw;
  display: flex;
  flex: 1;
  height: 100%;
  margin-left: -1.5rem;
}
