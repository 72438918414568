.se-form {
  form {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    margin-right: 2rem;
    .MuiTextField-root {
      margin-top: 1rem;
    }
  }
  .alert {
    margin-top: 2rem;
  }
}

.se-buttons {
  margin-top: 1rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.se-role-picker {
  margin-top: 1.5rem;
}

.se-role-picker-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.form-error {
  color: red;
}

.error-alert {
  color: #fff;
  font-weight: 500;
  background-color: #f44336;
  display: flex;
  padding: 6px 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.43;
  font-size: 2rem;
  border-radius: 4px;
  letter-spacing: 0.01071em;
}
