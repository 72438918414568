@import '../../common/styles/breakpoints';
@import '../../common/styles/colors';

$widget-items-max-width: 708px;
$widget-items-max-width-tablet: 480px;
.rewards {
  .reward-header-widget {
    background: $zt-black 0 0 no-repeat padding-box;
    width: 100vw;
    position: relative;
    left: -15px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 24px 32px;

    .unearned-rewards-btn {
      width: 100%;
      padding: 12px 22px 12px 24px;
      background: $zt-add-blue 0 0 no-repeat padding-box;
      border-radius: 1px;
      font: normal normal 600 14px/21px Inter;
      letter-spacing: 0;
      color: #FFFFFF;
      max-width: $widget-items-max-width;
      @media only screen and (max-width: $tabletBreakpoint) {
        max-width: $widget-items-max-width-tablet;
      }

      &:focus {
        text-decoration: none;
      }

      @media only screen and (max-width: $phoneBreakpoint) {
        margin-top: 12px;
      }
      @media only screen and (min-width: $phoneBreakpoint) {
        margin-top: 20px;
      }

      div {
        display: flex;
        justify-content: space-between;
        svg {
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(225deg) brightness(101%) contrast(102%);
        }
      }
    }

    .rewards-earned-section {
      border-radius: 4px;
      width: 100%;
      border: 1px solid #FFFFFF40;
      padding: 16px 61px 16px 26px;
      @media only screen and (max-width: $phoneBreakpoint) {
        padding-top: 24px;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      max-width: $widget-items-max-width;
      @media only screen and (max-width: $tabletBreakpoint) {
        max-width: $widget-items-max-width-tablet;
      }


      .rewards-earned-header {
        font: normal normal 600 16px/21px Inter;
        margin-bottom: 4px;
      }

      .rewards-earned-overview {
        @media only screen and (max-width: $phoneBreakpoint) {
          margin-bottom: 14px;
        }

        .rewards-earned-counter {
          @media only screen and (min-width: $phoneBreakpoint) {
            height: 36px;
            align-items: center;
          }
          display: flex;
          font: normal normal 600 32px/30px Inter;
          letter-spacing: -0.48px;
          color: #FFFFFF;

          img {
            margin-right: 8px;
            width: 28px;
          }
        }
      }


      .rewards-earned-details {
        min-width: 160px;
        flex-grow: 0;
        flex-shrink: 0;
        font: normal normal normal 14px/17px Inter;
        letter-spacing: 0;
        color: #FFFFFFDE;
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 8px;

        .red-value {
          font: normal normal 600 14px/17px Inter;
          letter-spacing: 0px;
          color: #FFFFFFDE;
        }
      }
    }
  }
}
