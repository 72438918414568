@import "src/common/styles/colors";
$styled-teal: #3CCFBE;
$styled-grey: #EEF0F4;
$img-width: 9rem;
$dark-grey-text: #2B2A2A;

@mixin track-panel {
  padding: 3rem;
  flex: 1;
  border-radius: 10px;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

@mixin zt-button {
  &:enabled{
    box-shadow: 2px 2px 14px 1px rgba(00,00,00,0.1);
  }

  &:hover {
    &:enabled {
      cursor: pointer;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  width: 109px;
  text-transform: uppercase;
  border: none;
  height: 43px;
  font-size: 20px;
  border-radius: 5px;
  font-weight: bold;
}

.save-btn {
  background-color: $styled-teal;
  color: white;
  @include zt-button;
}
