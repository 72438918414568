
.overview-feedback-card {
  padding: 44px 30px 30px;

  .tag-item {
    cursor: default;
  }

  .selected-rating {
    margin-bottom: -5px;
    font: normal normal 600 18px/21px Inter;
    text-align: center;
  }

  .overview-feedback-card-header {
    font: normal normal 600 24px/27px Inter;
    margin-bottom: 10px;
    text-align: center;
  }

  .tag-prompt {
    color: black
  }

  .feedback-card-comments {
    font-family: Inter, sans-serif;
    margin-top: 10px;
  }
}

.feedback-card-modal {
  border-radius: 4px;
  width: 600px;
  background-color: white;

  .close-button {
    position: absolute;
  }
}

