@import "../../../../../../common/styles/colors";

.survey-card-wrapper {
  .card-course-card {
    background-color: $zt-black;
    box-shadow: unset;
    border-radius: 4px;
    padding: 24px 16px 16px;
  }
}

.survey-title {
  font: normal normal 600 18px/21px Inter;
  color: white;
  text-align: center;
}


.survey-submit-button-wrapper {
  .catalyst-button-contained {
    color: $zt-black;

    &:disabled {
      color: #07070780;
      background: #FFFFFF59 0 0 no-repeat padding-box;
    }

    background-color: #FFFFFFDE;
  }
}
