.sticky-footer-cta {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  height: 70px;
  //background-color: black;
  //color: white;
  position: fixed;
  bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto, sans-serif;
  outline: none;
  @media only screen and (max-width: 600px) {
    width: calc(100vw - 30px - 5rem);
    left: calc(50% - (100vw - 30px - 5rem) / 2);
  }
  @media only screen and (min-width: 600px) {
    width: calc(600px - 3rem);
    left: calc(50% - (600px - 3rem) / 2);
  }
}

.gmap-modal-content {
  width: calc(100vw);
  height: calc(100vh - 250px);
  outline: none;

  .lfc-map {
    height: calc(100vh - 250px);
  }
}

.gmap-wrap {
  position: relative;

  .lfc-locating {
    color: white;
    position: absolute;
    left: 1rem;
    top: -4rem;
  }
}

.tap-to-close {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 2rem;
  color: white;
  font-family: Roboto, sans-serif;
  outline: none;
  font-size: 18px;
}
